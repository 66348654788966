import { userLogin } from "../api/user";

export const callbackFunc = async (otplessUser) => {
  const tokenObj = {
    token: otplessUser.token,
    email: otplessUser.email?.email,
    waNumber: otplessUser.waNumber,
    waName: otplessUser.email?.name !== null ? otplessUser.email?.name : "",
  };
  const userData = await userLogin(tokenObj);
  const { auth_token, ...userInfo } = userData.data.data;
  localStorage.setItem("auth_token", auth_token);
  localStorage.setItem("user_details", JSON.stringify(userInfo));
  localStorage.setItem("otpless_user_token", JSON.stringify(tokenObj));
  // setUserData(otplessUser);
  closeModal();
  // navigate(0);
};

export const initOTPless = (callback) => {
  const otplessInit = Reflect.get(window, "otplessInit");

  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://otpless.com/auth.js";
    script.id = "otplessIdScript";
    // script.setAttribute('cid', 'YOUR_CID_HERE')
    document.body.appendChild(script);
  };

  otplessInit ? otplessInit() : loadScript();

  Reflect.set(window, "otpless", callback);
};

const closeModal = () => {
  const modalContainer = document.getElementById("modalContainer");
  if (modalContainer) {
    modalContainer.style.display = "none";
  }
  const script = document.getElementById("otplessIdScript");
  if (script) {
    script.remove();
  }
};

export const authModal = (userData) => {
  if (userData) {
    return;
  }
  initOTPless(callbackFunc);
  const modalContainer = document.getElementById("modalContainer");
  if (modalContainer) {
    modalContainer.style.display = "flex";
  }
};
