import Navbar from '../Components/Layouts/Navbar';
import FirstSection from '../Components/About/FirstSection';
import SecondSection from '../Components/About/SecondSection';
import ThirdSection from '../Components/About/ThirdSection';
import FourthSection from '../Components/About/FourthSection';
import Footer from '../Components/Layouts/Footer';
import FifthSection from '../Components/About/FifthSection';

const About = () => {
	return (
		<div style={{ backgroundColor: '#000000' }}>
			<FirstSection />
			<SecondSection />
			<ThirdSection />
			<FourthSection />
			<FifthSection />
		</div>
	);
};

export default About;
