import React from "react";
import { Row, Col } from "antd";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { ScrollRestoration } from "react-router-dom";
const stickyCss = {
  position: "sticky",
  top: "0px",
  "z-index": "100",
  // boxShadow: "0px 1px 2px rgba(0,255,255,0.5), 0px 2px 4px rgba(0,255,255,0.5), 0px 4px 8px rgba(0,255,255,0.5), 0px 8px 16px rgba(0,255,255,0.5)"
};
const AppLayout = ({ Component }) => {
  return (
    <Row>
      <Col span={24} style={stickyCss}>
        <Navbar />
      </Col>
      <Col span={24}>
        <Component />
      </Col>
      <Col span={24}>
        <Footer />
      </Col>

      <script type="text/javascript" src="https://otpless.com/auth.js"></script>
      <ScrollRestoration />
    </Row>
  );
};

export default AppLayout;
