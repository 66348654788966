import { Card, Row, Col, Button, Space, Empty, Typography, Flex } from 'antd';
import { useEffect, useState } from 'react';
import { ShoppingCartOutlined, LeftOutlined } from '@ant-design/icons';
import deleteIcon from '../../assets/images/deleteIcon.png';
import './CartStyle/firstSection.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import image1 from '../../assets/images/Vector5.svg';
import { AiTwotoneDelete } from 'react-icons/ai';
import {
	addProductToCart,
	deleteProductFromCart,
	getUserAddress,
	getUserCartList,
	updateProductFromCart,
} from '../../api/user';
import { updateCartItem } from '../../Redux/actionTypes';
import { useNavigate } from 'react-router-dom';
import { formatToRupees } from '../../utils/formatToRupees';
const { Text } = Typography;

const ResponsiveCartDetail = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [totalAmount, setTotalAmount] = useState(0);
	const cartItems = useSelector((state) => state.cart.cartItems);
	const [addressList, setAddressList] = useState([]);

	const incrementQuantity = (productDetails) => {
		const productDetailsAndCount = {
			product_id: productDetails.product_id,
			count: 1,
		};
		addProductToCart(productDetailsAndCount).then((response) => {
			getUserCartList().then((response) => {
				dispatch(updateCartItem(response));
			});
		});
	};

	const onCheckoutButtonClicked = () => {
		navigate('/checkout/address');
	};

	const deleteItemFromCart = async (productDetails) => {
		const productidObj = {
			id: productDetails.id,
		};
		await deleteProductFromCart(productidObj);
		getUserCartList().then((response) => {
			dispatch(updateCartItem(response));
		});
	};

	const decrementQuantity = async (productDetails) => {
		if (productDetails.count > 1) {
			const payload = {
				id: productDetails.id,
				count: productDetails.count - 1,
			};
			await updateProductFromCart(payload);
			getUserCartList().then((response) => {
				dispatch(updateCartItem(response));
			});
		}
	};

	const getTotalAmount = (cartItems) => {
		let totalAmount = 0;
		if (cartItems?.length) {
			cartItems.forEach((item) => {
				console.log(item.booking_type + item.bookingPrice);
				totalAmount +=
					item.booking_type == 'booking'
						? item.bookingPrice * item.count
						: (item.variant && item.variant != null
								? getVariantPrice(item)
								: item.sellingPrice) * item.count;
			});
		}
		setTotalAmount(totalAmount);
	};

	const getVariantPrice = (cartItem) => {
		if (cartItem && cartItem.variants && cartItem.variants.length) {
			let variant = cartItem.variant;
			let selectedVariant = cartItem.variants.filter(
				(item) => item.spec === variant
			);

			if (selectedVariant.length > 0) {
				return selectedVariant[0].price;
			}
		}
		return cartItem.sellingPrice;
	};

	const getAndStoreUserAddress = async () => {
		const addressList = await getUserAddress();
		setAddressList(addressList);
	};

	useEffect(() => {
		getAndStoreUserAddress();
	}, []);

	useEffect(() => {
		getTotalAmount(cartItems);
	}, [cartItems]);

	return (
		<>
			<div
				onClick={() => navigate(-1)}
				style={{ margin: '10px 20px', display: 'flex', width: '140px' }}
			>
				<Text className="back-btn-text" style={{ height: '40px' }}>
					{' '}
					<LeftOutlined className="product-detail-back-btn-icon" />
					Back
				</Text>
			</div>
			<Row>
				<Col xs={24} sm={24} style={{ padding: '10px 25px' }}>
					<section
						className="responsive-cart-container"
						style={{ backgroundColor: '#000000' }}
					>
						{cartItems?.length > 0 ? (
							cartItems.map((item, index) => {
								let productDisplayImage = item.productImages?.split(',')?.[0];
								return (
									<div key={index} className="responsive-cart-container-header">
										<Row gutter={10}>
											<Col span={8}>
												<div className="responsive-cart-image-container">
													<img
														src={productDisplayImage}
														className="responsive-cart-image"
														alt="Product"
													/>
												</div>
											</Col>
											<Col span={16}>
												<div style={{ paddingLeft: '15px' }}>
													<div style={{ marginTop: '10px' }}>
														<Text className="responsive-cart-item-name">
															{item.name}
														</Text>
														<div>
															<div style={{ marginTop: '10px' }}>
																{' '}
																<Text
																	className="responsive-cart-item-name"
																	style={{ fontSize: '14px' }}
																>
																	{item.brandName}
																</Text>
															</div>
															<div style={{ marginTop: '10px' }}>
																{' '}
																<Text
																	className="responsive-cart-item-name"
																	style={{ fontSize: '14px' }}
																>
																	{item?.variant} - {item?.selectedColor}
																</Text>
															</div>
															<div style={{ marginTop: '10px' }}>
																<Text
																	className="responsive-cart-item-name"
																	style={{ fontSize: '14px' }}
																>
																	{item.booking_type}
																</Text>
															</div>
														</div>
														<Text className="responsive-cart-item-price">
															Rs.{' '}
															{formatToRupees(
																item.variant && item.variant != null
																	? getVariantPrice(item)
																	: item.sellingPrice
															)}
														</Text>
													</div>
													<div className="responsive-quantity-container">
														<Flex>
															<div className="responsive-quantity-container-wrapper">
																<span>
																	<Text
																		onClick={() => decrementQuantity(item)}
																		className="increment-decrement-btn"
																	>
																		-
																	</Text>
																</span>
																<span className="item-count">{item.count}</span>
																<span>
																	<Text
																		onClick={() => incrementQuantity(item)}
																		className="increment-decrement-btn"
																	>
																		+
																	</Text>
																</span>
															</div>
															<AiTwotoneDelete
																className="delete-btn"
																style={{ color: '#fff' }}
																onClick={() => deleteItemFromCart(item)}
															/>
														</Flex>
													</div>
												</div>
											</Col>
										</Row>
									</div>
								);
							})
						) : (
							<div
								style={{
									height: '70vh',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Empty
									image={
										<ShoppingCartOutlined
											style={{ fontSize: 100, margin: '40px 0' }}
										/>
									}
									imageStyle={{
										height: 100,
									}}
									description={
										<Space direction="vertical" style={{ margin: '50px 0' }}>
											<span
												style={{
													fontFamily: 'AvenirNextLTPro',
													fontWeight: '600',
													fontSize: '22px',
												}}
											>
												Your cart is empty
											</span>
											{/* Add additional message or content here */}
											<Link to={'/products'}>
												<Text className="continue">Continue shopping</Text>
											</Link>
										</Space>
									}
								/>
							</div>
						)}
					</section>
				</Col>
				{cartItems?.length > 0 && (
					<Col xs={24} sm={24} style={{ padding: '40px 25px' }}>
						<div style={{ width: '100%' }}>
							<div className="responsive-cart-checkout-container">
								<div style={{ paddingBottom: '5px' }}>
									<Text className="responsive-cart-selected-count">
										Selected Item ({cartItems?.length})
									</Text>
								</div>
								<div style={{ paddingBottom: '3px' }}>
									<Text className="responsive-cart-subtotal">
										Subtotal : Rs. {formatToRupees(totalAmount)}
									</Text>
								</div>
								<div>
									<Text className="responsive-cart-checkout-help-text">
										Taxes and shipping calculated at checkout
									</Text>
								</div>
							</div>
							<div
								type="flex"
								justify="center"
								className="pt"
								style={{ width: '100%' }}
							>
								<Link to={'/checkout/address'}>
									<Button
										className="gradient-btn"
										style={{
											width: '100%',
											fontSize: '18px',
											height: '55px',
											padding: '10px 0',
										}}
									>
										Checkout
									</Button>
								</Link>
							</div>
						</div>
					</Col>
				)}
			</Row>
		</>
	);
};

export default ResponsiveCartDetail;
