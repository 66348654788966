import React from 'react';
import { Row, Col, Typography, Image } from 'antd';
import './aboutStyle/secondSection.css';

const { Text, Paragraph } = Typography;

const SecondSection = () => {
	return (
		<Row className="about-second-section-container">
			<Col xs={24} md={12} lg={12} className="about-second-left-container">
				<Paragraph className="about-second-title">Our Mission:</Paragraph>
				<Paragraph className="about-second-description">
					At Lilypad, our mission is to revolutionise the way India moves, by
					making electric vehicles accessible to everyone. We're not just a
					marketplace; we're advocates for a sustainable future.
				</Paragraph>
				<Paragraph className="about-second-description">
					Through relentless pursuit of innovation, customer-centricity, and a
					comprehensive platform, Lilypad is where India’s journey to electric
					mobility begins—driven by knowledge, choice, and the desire to make a
					difference.
				</Paragraph>
			</Col>
			<Col xs={24} md={12} lg={12} className="about-second-right-container">
				<Image
					className="second-image"
					src="./../images/Free_Pole_Banner_Mockup_1.png"
					preview={false}
				/>
			</Col>
		</Row>
	);
};

export default SecondSection;
