// authActions.js - Redux action creators for authentication
export const setAuthToken = (token) => ({
	type: 'SET_AUTH_TOKEN',
	payload: token,
});

export const setUserDetails = (user) => ({
	type: 'SET_USER_DETAILS',
	payload: user,
});
// other action creators related to authentication

export const setUserCoords = (data) => ({
	type: 'GET_USER_COORDS_SUCCESS',
	payload: data,
});

export const setUserPincode = (data) => ({
	type: 'GET_USER_PINCODE_SUCCESS',
	payload: data,
});

export const setUserPincodeEdited = (data) => ({
	type: 'GET_USER_PINCODE_SUCCESS_Edited',
	payload: data,
});
export const setIsLocationEnabled = (payload) => ({
	type: 'SET_IS_LOCATION_ENABLED',
	payload: payload,
});

export const setAddresses = (payload) => ({
	type: 'SET_ADDRESS',
	payload: payload,
});

export const setAddedAddress = (payload) => ({
	type: 'SET_ADDED_ADDRESS',
	payload: payload,
});

export const setEditedAddress = (payload) => ({
	type: 'SET_EDITED_ADDRESS',
	payload: payload,
});

export const setDeletedAddress = (payload) => ({
	type: 'SET_DELETED_ADDRESS',
	payload: payload,
});
