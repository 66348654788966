import React, { useEffect, useRef, useState } from "react";
import './style.css'
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/es/input/TextArea";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addAddress, editAddress, getAddress } from "../../api/user";
import Link from "antd/es/typography/Link";
import { CaretDownOutlined } from "@ant-design/icons";
import { setEditedAddress } from "../../Redux/actionTypes/authAction";

const { Item } = Form;
const { Option } = Select;
const states = [
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "Odisha", value: "Odisha" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" },
  { label: "Andaman and Nicobar Islands", value: "Andaman and Nicobar Islands" },
  { label: "Chandigarh", value: "Chandigarh" },
  { label: "Dadra and Nagar Haveli and Daman and Diu", value: "Dadra and Nagar Haveli and Daman and Diu" },
  { label: "Delhi", value: "Delhi" },
  { label: "Lakshadweep", value: "Lakshadweep" },
  { label: "Puducherry", value: "Puducherry" },
];

const ShippingSection = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const [shippingAddress, setShippingAddress] = useState([]);
  let [searchParams] = useSearchParams();
  const addressId = searchParams.get('addressId');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = useForm();
  form.setFieldsValue({
    email: userDetails?.email,
  });
  form.setFieldsValue({
    mobile: userDetails?.mobile,
  });

  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    emailMe: false,
    countryRegion: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    saveInfo: false,
  });

  const openLoginModal = () => {
    // createLogicPage();
    window.otplessInit();
    const modalContainer = document.getElementById("modalContainer");
    const modal = document.getElementById("otpless-login-page");
    modalContainer.style.display = "flex";
    modal.style.display = modal.style.display === "block" ? "none" : "block";
  };

  const handleChange = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleSubmit = (values) => {
    if (addressId) {
      values['id'] = addressId
      editAddress(values).then((response) => {
        dispatch(setEditedAddress(response))
        window.localStorage.setItem("selectedAddress", JSON.stringify(response));
        navigate("/checkout/payment");
      });
    }
    else {
      addAddress(values).then((response) => {
        window.localStorage.setItem("selectedAddress", JSON.stringify(response));
        navigate("/checkout/payment");
      });
    }
  };

  const onSearch = (value) => { };

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onContinueToShipClicked = async () => {
    let values = form.getFieldsValue(true);
    delete values?.remember;
    delete values?.email;
    await addAddress(values).then((response) => {
      window.localStorage.setItem("selectedAddress", JSON.stringify(response));
      navigate("/checkout/payment");
    });
  };

  const getUserAddressForEdit = async () => {
    const response = await getAddress(addressId)

    const initialValues = response
      ? {
        contact: response.contact,
        countryRegion: response.countryRegion,
        firstName: response.firstName,
        lastName: response.lastName,
        address: response.address,
        city: response.city,
        state: response.state,
        pincode: response.pincode,
        save_as: response.save_as,
        // mobile: response.mobile
      }
      : {};

    form.setFieldsValue(initialValues);
  }

  useEffect(() => {
    if (addressId) {
      getUserAddressForEdit()
    }
  }, []);

  return (
    <Col xs={24} sm={24} md={20} lg={12}>
      <div
        className="shipping-section"
      >
        <div>
          <Typography style={{ fontSize: "22px", fontWeight: "600", fontFamily: 'AvenirNextLTPro' }}>
            Contact{" "}
          </Typography>
        </div>

        {!userDetails?.email ? (
          <div style={{ textAlign: "right" }}>
            <Typography style={{ color: "gray" }}>
              Have an account?
              <span
                style={{
                  color: "#04B2A9",
                  fontWeight: "bold",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => openLoginModal()}
              >
                {" "}
                Login{" "}
              </span>
            </Typography>
          </div>
        ) : (
          <></>
        )}

        <Form
          form={form}
          name="loginForm"
          initialValues={{ remember: true }}
          style={{ marginTop: "5px" }}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Item
                name="contact"
                rules={[
                  {
                    required: true,
                    message: "Please enter you email or mobile!",
                  },
                  {
                    pattern: "^([0-9]{12})|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$",
                    message: "please enter correct email or mobile",
                  }
                ]}
              >
                <Input placeholder="Enter you email or mobile" className="address-form-inputs" />
              </Item>
            </Col>
          </Row>
          <Row>
            {/* <Item
                    name="isSubscribedForNewsAndOffer"
                    valuePropName="checked"
                    style={{ color: "gray" }}
                  >
                    <Checkbox
                      checked={formData.emailMe}
                      onChange={(e) =>
                        handleChange("emailMe", e.target.checked)
                      }
                    >
                      Email me with news and offers
                    </Checkbox>
                  </Item> */}
          </Row>
          <Typography style={{ fontSize: "25px", fontWeight: "600", fontFamily: 'AvenirNextLTPro' }}>
            Shipping address
          </Typography>
          <Row gutter={16} style={{ marginTop: "5px" }}>
            <Col span={24}>
              <Item
                name="countryRegion"
                rules={[
                  {
                    required: true,
                    message: "please enter country name",
                  },
                  {
                    pattern: "^[a-zA-Z ]+$",
                    message: "please enter only alphabets",
                  }
                ]}
              >
                <Input className="address-form-inputs" placeholder="Country name" />
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                  {
                    pattern: "^[a-zA-Z ]+$",
                    message: "please enter only alphabets",
                  }
                ]}
              >
                <Input className="address-form-inputs" placeholder="First Name" />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                  {
                    pattern: "^[a-zA-Z ]+$",
                    message: "please enter only alphabets",
                  }
                ]}
              >
                <Input className="address-form-inputs" placeholder="Last Name" />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Item
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please input your Address!",
                  },
                ]}
              >
                <TextArea className="address-form-inputs" placeholder="Address" />
              </Item>
            </Col>
          </Row>
          {/* <Typography style={{ fontWeight: "bold" }}>
                  + Add apartment, suite, etc.
                </Typography> */}
          <Row gutter={16}>
            <Col span={8} xs={12} sm={12}>
              <Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please enter your city!",
                  },
                  {
                    pattern: "^[a-zA-Z ]+$",
                    message: "please enter only alphabets",
                  }
                ]}
              >
                <Input className="address-form-inputs" placeholder="City" />
              </Item>
            </Col>
            <Col span={8} xs={12} sm={12}>
              <Item
                name="state"
                rules={[
                  {
                    required: true,
                    message: "please select state",
                  },
                ]}
              >
                <Select
                  className="address-form-select"
                  showSearch
                  placeholder="Select a state"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={states}
                  suffixIcon={<CaretDownOutlined className="address-form-select-arrow" />}
                />
                {/* <Select
                  className="address-form-select"
                  placeholder="Select State"
                  suffixIcon={<CaretDownOutlined className="address-form-select-arrow" />}
                >
                  {states.map((state, index) => (
                    <Option key={index} value={state}>
                      {state}
                    </Option>
                  ))}
                </Select> */}
              </Item>
            </Col>
            <Col span={8} xs={12} sm={12}>
              <Item
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: "Please enter your mobile",
                  },
                  {
                    pattern: "^[0-9]{12}$",
                    message: "please enter correct mobile!",
                  }
                ]}
              >
                <Input className="address-form-inputs" type="number" ref={inputRef} onKeyDown={handleKeyDown} placeholder="Please add 91 as prefix for mobile number" />
              </Item>
            </Col>
            <Col span={8} xs={12} sm={12}>
              <Item
                name="pincode"
                rules={[
                  {
                    required: true,
                    message: "Please enter your pincode!",
                  },
                ]}
              >
                <Input className="address-form-inputs" type="number" ref={inputRef} onKeyDown={handleKeyDown} placeholder="PIN code" />
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8} xs={24} sm={24}>
              <Item
                name="save_as"
                rules={[
                  {
                    required: true,
                    message: "please select address type",
                  },
                ]}
              >
                <Select
                  className="address-form-select"
                  suffixIcon={<CaretDownOutlined className="address-form-select-arrow" />}
                  placeholder="Select address type"
                >
                  <Option className="select-option" value="Home">Home</Option>
                  <Option className="select-option" value="Work">Work</Option>
                </Select>
              </Item>
            </Col>
          </Row>
          {/* <Row gutter={16}>
                  <Col>
                    <Item
                      name="saveAddresss"
                      valuePropName="checked"
                      style={{ color: "gray" }}
                    >
                      <Checkbox
                        onChange={(e) =>
                          handleChange("saveInfo", e.target.checked)
                        }
                      >
                        Save this information for next time
                      </Checkbox>
                    </Item>
                  </Col>
                </Row> */}
          <Row>
            <Col span={24} style={{ margin: '20px 0' }}>
              <Item>
                {/* <Button
                  htmlType="submit"
                  style={{
                    backgroundColor: "#04B2A9",
                    color: "white",
                    height: "48px",
                  }}
                >
                  Save Address
                </Button> */}
                <Button
                  htmlType="submit"
                  className="gradient-btn continue-to-shipping"
                // onClick={() => onContinueToShipClicked()}
                >
                  Continue To Shipping
                </Button>
              </Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Col>
  );
};

export default ShippingSection;
