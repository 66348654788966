import React, { useEffect, useState } from "react";
import ProductList from "../Components/Products/ProductList";
import {
  MenuUnfoldOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import filter from "../../src/assets/images/filter.svg";
import sortIcon from "../../src/assets/images/sortIcon.svg";
import {
  Button,
  Divider,
  Drawer,
  Dropdown,
  Image,
  Layout,
  Space,
  message,
} from "antd";
import ProductListHeader from "../Components/Products/ProductListHeader";
import ProductOfferBanner from "../Components/Layouts/ProductOfferBanner";
import {
  getAllBrands,
  getAllCategories,
  getAllProduct,
  getFiltered,
} from "../api/product";
import PriceFilter from "../Components/PriceFilter";
import RatingFilter from "../Components/RatingFilter";
import CategoryFilter from "../Components/CategoryFilter";
import ColorFilter from "../Components/ColorFilter";
import BrandFilter from "../Components/BrandFilter";
import LocationFilter from "../Components/LocationFilter";
import { useDispatch, useSelector } from "react-redux";
import { getUserPincode } from "../utils/googleMaps";
import { setUserPincode } from "../Redux/actionTypes/authAction";
import { getPincodesForAllStates } from "../api/user";
import { debounce } from "../utils/debounce";
import {
  setBrandsData,
  setCategoriesData,
  setProductColors,
  setProductMaxPrice,
} from "../Redux/actionTypes/productAction";
import DisclaimerModel from '../modals/DisclaimerModel';

const { Sider, Content } = Layout;

const contentStyle = {
  minHeight: "320px",
  lineHeight: "10px",
  color: "#fff",
  backgroundColor: "#1d1e18",
  padding: "0 10px",
};

const siderStyle = {
  // textAlign: "center",
  background: "#1d1e18",
  flexDirection: "column",
  justifyContent: "center",
  padding: "0 40px",
  borderRight: "1px solid #E7E7E7",
};

const layoutWithBorderStyle = {
  borderRight: "1px solid #E7E7E7",
  borderRadius: "5px",
  padding: "10px",
};

const ProductsStore = () => {
  const states = [
    "Maharashtra",
    "Uttar Pradesh",
    "Bihar",
    "West Bengal",
    "Madhya Pradesh",
    "Tamil Nadu",
    "Rajasthan",
    "Karnataka",
    "Gujarat",
    "Andhra Pradesh",
    "Odisha",
    "Telangana",
    "Kerala",
    "Jharkhand",
    "Assam",
    "Punjab",
    "Chhattisgarh",
    "Haryana",
    "Uttarakhand",
    "Himachal Pradesh",
    "Tripura",
    "Meghalaya",
    "Manipur",
    "Nagaland",
    "Goa",
    "Arunachal Pradesh",
    "Mizoram",
    "Sikkim",
    "Delhi",
    "Jammu and Kashmir",
  ];
  const authState = useSelector((state) => (state ? state.auth : []));
  const productState = useSelector((state) => (state ? state.product : []));
  const [pageLoaded, setIsPageLoaded] = useState(false);
  const [products, setProducts] = useState([]);
  const [lastKey, setLastKey] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sortingOrder, setSortingOrder] = useState("");
  const [sortingCriteria, setSortingCriteria] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedColor, setSelectedColor] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedBrandLabels, setSelectedBrandLabels] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [maxPrice, setMaxPrice] = useState(0)
  const [selectedPriceRange, setSelectedPriceRange] = useState([0, 100]);
  const [selectedRating, setSelectedRating] = useState(undefined);
  const [brands, setBrands] = useState([]);
  const [listOffset, setListOffset] = useState(8);
  const [isResetFilter, setIsResetFilter] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const dispatch = useDispatch();
  const onCategoryChange = (selectedValue) => {
    setIsResetFilter(false);
    setSelectedCategory(selectedValue);
  };
  const onColorChange = (selectedValue) => {
    setIsResetFilter(false);
    setSelectedColor(selectedValue);
  };

  const onPriceSelected = (selectedPriceRange) => {
    setIsResetFilter(false);
    let priceRange = [];
    priceRange[0] = selectedPriceRange[0] * 1000;
    priceRange[1] = selectedPriceRange[1] * 1000;
    let filters = {
      sortingOrder,
      sortingCriteria,
      searchTerm: searchTerm.toLowerCase(),
      selectedColor,
      selectedCategory,
      priceRange,
      selectedRating,
      selectedBrand,
      selectedLocation,
    };
    const getFilteredData = debounce(async () => {
      setIsLoading(true);
      try {
        const data = await getFiltered(
          {
            city: authState.userPincode?.city,
            district: authState.userPincode?.district,
            state: authState.userPincode?.state,
          },
          filters
        );
        if (data) {
          if (sortingCriteria === "Available") {

          }
          setFilteredProducts(data.filteredProducts);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        message.error("Error fetching products!");
      }
    }, 500);

    if (pageLoaded) {
      getFilteredData();
    }
    setSelectedPriceRange(selectedPriceRange);
  };

  const onBrandChange = (selectedBrand, selectedBrandLabels) => {
    setIsResetFilter(false);
    setSelectedBrand(selectedBrand);
    setSelectedBrandLabels(selectedBrandLabels);
  };

  const onLocationChange = (selectedLocation) => {

    setIsResetFilter(false);
    setSelectedLocation(selectedLocation);
  };

  const onRatingSelected = (selectedRating) => {
    setIsResetFilter(false);
    setSelectedRating(selectedRating);
  };

  const onTextEntered = (text) => {
    setSearchTerm(text);
  };

  const [orderKeys, setOrderKeys] = useState({ price: "asc", discount: "asc", rating: "asc", availableLocations: "asc" })

  const onSortingChange = (text) => {
    if (text === "date") {
      setSortingCriteria("createdAt");
      setSortingOrder(text);
    } else if (text === "price") {
      setOrderKeys(prevOrderKeys => ({
        ...prevOrderKeys,
        price: prevOrderKeys.price === "asc" ? "desc" : "asc"
      }));
      setSortingCriteria("sellingPrice");
      setSortingOrder(orderKeys.price);
    } else if (text === "discount") {
      setOrderKeys(prevOrderKeys => ({
        ...prevOrderKeys,
        discount: prevOrderKeys.discount === "asc" ? "desc" : "asc"
      }));
      setSortingCriteria("discount");
      setSortingOrder(orderKeys.discount);
    } else if (text === "rating") {
      setOrderKeys(prevOrderKeys => ({
        ...prevOrderKeys,
        rating: prevOrderKeys.rating === "asc" ? "desc" : "asc"
      }));
      setSortingCriteria("ratings");
      setSortingOrder(orderKeys.rating);
    } else if (text === "availableLocations") {
      setOrderKeys(prevOrderKeys => ({
        ...prevOrderKeys,
        availableLocations: prevOrderKeys.availableLocations === "asc" ? "desc" : "asc"
      }));
      setSortingCriteria("availableLocations");
      setSortingOrder(orderKeys.availableLocations);
    } else {
      setSortingCriteria(text);
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");

  const showDrawer = () => {
    setOpen(true);
  };

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const onClose = () => {
    setOpen(false);
  };

  const fetchPincodesForAll = async () => {
    try {
      const { data } = await getPincodesForAllStates({ states });
      if (data) {
        //
      }
    } catch (error) {
      message.error("Failed to fetch pincodes for states!");
    }
  };

  // useEffect(() => {
  // fetchPincodesForAll();
  // }, []);

  useEffect(() => {

    let priceRange = [];
    priceRange[0] = selectedPriceRange[0] * 1000;
    priceRange[1] = selectedPriceRange[1] * 1000;
    let filters = {
      sortingOrder,
      sortingCriteria,
      searchTerm: searchTerm.toLowerCase(),
      selectedColor,
      selectedCategory,
      priceRange,
      selectedRating,
      selectedBrand,
      selectedBrandLabels,
      selectedLocation,
    };

    const getFilteredData = debounce(async () => {
      setIsLoading(true);
      try {
        const data = await getFiltered(
          {
            city: authState.userPincode?.city,
            district: authState.userPincode?.district,
            state: authState.userPincode?.state,
          },
          filters
        );
        if (data) {
          setFilteredProducts(data.filteredProducts);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        message.error("Error fetching products!");
      }
    }, 500);

    if (pageLoaded) {
      getFilteredData();
    }

  }, [
    sortingOrder,
    searchTerm,
    selectedColor,
    selectedCategory,
    // selectedPriceRange,
    selectedRating,
    selectedBrand,
    selectedBrandLabels,
    selectedLocation,
    sortingCriteria,
  ]);

  const resetFilters = () => {
    setIsResetFilter(true);
    setSortingCriteria("");
    setSortingOrder("");
    setSelectedBrand([]);
    setSelectedBrandLabels([]);
    setSelectedCategory([]);
    setSelectedColor([]);
    setSelectedLocation([]);
    setSelectedPriceRange([0, maxPrice]);
    setSearchTerm("");
    setLastKey(undefined);
    setSelectedRating(undefined);
  };

  useEffect(() => {
    // Event handler to update windowWidth state
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    const handleReload = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);
    window.addEventListener("reload", handleReload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("reload", handleReload);
    };
  }, [windowWidth]);

  const getProductFromApi = async (offset, isConcat) => {
    setIsLoading(true);
    try {
      let filters = {
        sortingOrder,
        sortingCriteria,
        searchTerm: searchTerm.toLowerCase(),
        selectedColor,
        selectedCategory,
        selectedRating,
        selectedBrand,
        selectedBrandLabels,
        selectedLocation,
      };
      const prod = await getFiltered(offset, filters);
      if (isConcat && products.length) {
        prod.data = [...products, ...prod.data]
      }
      if (prod) {
        const published = prod?.data
        let maxPriceProduct = Math.max(...published.map(product => product.sellingPrice));
        maxPriceProduct = (maxPriceProduct + 10000) / 1000
        dispatch(setProductMaxPrice(maxPriceProduct))
        setMaxPrice(maxPriceProduct)
        setSelectedPriceRange([0, maxPriceProduct])
        setLastKey(prod.lastKey);
        dispatch(setProductColors(prod?.colors));
        setProducts(published);
        setFilteredProducts(published);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      // console.error("Error fetching products:", error);
    }
  };

  const getBrands = async () => {
    if (productState?.brands?.length === 0) {
      try {
        // const brands = productState?.products?.map((item) => ({
        //   label: item.brandName,
        //   value: item.vendorId,
        // }));
        // setBrands(brands);
        // dispatch(setBrandsData(brands));
        const brands = await getAllBrands();
        const uniqueBrands = [
          ...new Set(brands.map((obj) => JSON.stringify(obj))),
        ].map((str) => JSON.parse(str));
        setBrands(uniqueBrands);
        dispatch(setBrandsData(uniqueBrands));
      } catch (error) {
        message.error("Failed to fetch brand filter data!");
      }
    } else {
      const brands = productState?.brands;
      setBrands(brands);
    }
  };

  const getCategories = async () => {
    try {
      const categories = await getAllCategories();
      if (categories) {
        categories.push({ name: "All" });
        const allObject = categories.find(item => item.name === "All");
        const remainingData = categories.filter(item => item !== allObject);

        const sortedRemainingData = remainingData.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        let final_categories = [allObject, ...sortedRemainingData]
        final_categories = final_categories.filter((item) => item.isPublished);
        dispatch(setCategoriesData(final_categories));
      }
    } catch (error) {
      // console.error("Failed to fetch categories!");
    }
  };

  const getPincode = async () => {
    if (productState?.products?.length === 0) {
      const pincode = await getUserPincode(authState.userCoords);
      dispatch(setUserPincode(pincode));
    }
  };

  const loadMoreProducts = (nextPage) => {
    //
    getProductFromApi({
      city: authState.userPincode?.city,
      district: authState.userPincode?.district,
      state: authState.userPincode?.state,
      lastKey: lastKey?.id
    }, true)
  };

  useEffect(() => {
    if (authState.userCoords !== undefined) {
      getPincode();
    }
    setIsPageLoaded(true);
  }, [authState.userCoords]);

  useEffect(() => {
    if (productState?.products?.length > 0) {
      var maxPriceProduct = Math.max(...productState?.products.map(product => product.sellingPrice));
      maxPriceProduct = (maxPriceProduct + 10000) / 1000
      setMaxPrice(maxPriceProduct)
      setSelectedPriceRange([0, maxPriceProduct])
      setProducts(productState?.products);
      setFilteredProducts(productState?.products);
      setIsLoading(false);
    } else if (productState?.products?.length === 0) {
      getProductFromApi({
        city: authState.userPincode?.city,
        district: authState.userPincode?.district,
        state: authState.userPincode?.state,
      });
    }

    getBrands();

    if (productState?.categories?.length === 0) {
      getCategories();
    }
  }, [authState.userPincode, listOffset]);

  // useEffect(() => {
  //   if (sortingCriteria === "Available") {
  //     const products = filteredProducts;
  //     products.sort((product1, product2) => {
  //       const product2Matches = (product2?.availableLocations?.some(r => Object.values(authState?.userPincode).includes(r)) || product2?.availableLocations?.includes("ALL_LOCATIONS")) ? 1 : 0;
  //       const product1Matches = (product1?.availableLocations?.some(r => Object.values(authState?.userPincode).includes(r)) || product1?.availableLocations?.includes("ALL_LOCATIONS")) ? 1 : 0;
  //       return product2Matches - product1Matches;
  //     });
  //     setFilteredProducts(products);
  //   }
  // }, [sortingCriteria])

  const items = [
    {
      label: "Price: Low to High",
      key: "price-asc",
      icon: <SortAscendingOutlined />,
    },
    {
      label: "Price: High to Low",
      key: "price-desc",
      icon: <SortDescendingOutlined />,
      sortingCriteria: "sellingPrice",

    },
    {
      label: "Rating: Low to High",
      key: "rating-asc",
      icon: <SortAscendingOutlined />,
      sortingCriteria: "ratings"
    }, {
      label: "Rating: High to Low",
      key: "rating-desc",
      icon: <SortDescendingOutlined />,
      sortingCriteria: "ratings"
    }, {
      label: "Discount: High to Low",
      key: "discount-desc",
      icon: <SortDescendingOutlined />,
      sortingCriteria: "discount",
    },
    {
      label: "Discount: Low to High",
      key: "discount-asc",
      sortingCriteria: "discount",
      icon: <SortAscendingOutlined />,
    },

    // {
    //   label: "Availability",
    //   key: "availability-dsc",
    //   sortingCriteria: "Available",
    //   icon: <SortAscendingOutlined />,
    // },

    {
      label: "Newest Arrivals",
      key: "date",
      icon: <></>,
      sortingCriteria: "createdAt",
    },
  ];

  const handleMenuClick = (e) => {
    const sortingOptions = {
      "date": { criteria: "createdAt", order: "date" },
      "price-desc": { criteria: "sellingPrice", order: "desc" },
      "discount-asc": { criteria: "discount", order: "asc" },
      "discount-desc": { criteria: "discount", order: "desc" },
      "rating-asc": { criteria: "ratings", order: "asc" },
      "rating-desc": { criteria: "ratings", order: "desc" },
      // "availability-asc": { criteria: "availableLocation", order: "asc" },
      // "availability-dsc": { criteria: "availableLocation", order: "desc" },
      "default": { criteria: "sellingPrice", order: "asc" }
    };

    const { criteria, order } = sortingOptions[e.key] || sortingOptions["default"];
    setSortingCriteria(criteria);
    setSortingOrder(order);
  };


  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      <ProductOfferBanner
        width={windowWidth}
        height={windowWidth >= 820 ? 150 : 80}
        title="Search For The Perfect Mobility Solution"
        buttonText="Buy Now"
        onTextEntered={onTextEntered}
        onSortingChange={onSortingChange}
      />
      {/* {windowWidth < 800 && (
        <ProductListHeader
          onTextEntered={onTextEntered}
          onSortingChange={onSortingChange}
        />
      )} */}
      {windowWidth > 800 ? (
        <Layout hasSider>
          <Sider style={siderStyle} width={300}>
            <CategoryFilter
              onCategoryChange={onCategoryChange}
              isResetFilter={isResetFilter}
            />
            <Divider style={{ margin: "16px 0" }} />
            {maxPrice > 0 && (
              <PriceFilter
                onPriceSelected={onPriceSelected}
                isResetFilter={isResetFilter}
                maxPrice={maxPrice}
              />
            )}
            <Divider style={{ margin: "16px 0" }} />
            <RatingFilter
              onRatingSelected={onRatingSelected}
              isResetFilter={isResetFilter}
            />
            <Divider style={{ margin: "16px 0" }} />
            <LocationFilter
              onLocationChange={onLocationChange}
              isResetFilter={isResetFilter}
            />
            <Divider style={{ margin: "16px 0" }} />
            <ColorFilter
              onColorChange={onColorChange}
              isResetFilter={isResetFilter}
            />
            <Divider style={{ margin: "16px 0" }} />
            <BrandFilter
              brands={brands}
              onBrandChange={onBrandChange}
              isResetFilter={isResetFilter}
            />
            <Divider style={{ margin: "16px 0" }} />
            <Button
              style={{
                margin: "10px 0 30px 0",
                fontFamily: "AvenirNextLTPro",
                width: "100%",
              }}
              onClick={resetFilters}
            >
              Reset Filters
            </Button>
          </Sider>
          <Content style={contentStyle}>
            <ProductList
              fetchData={loadMoreProducts}
              isLoading={isLoading}
              products={filteredProducts}
              lastKey={lastKey}
            />

            {/* <DisclaimerModel isModalOpen={!isModalOpen} onModalClose={onModalClose} /> */}
          </Content>
        </Layout>
      ) : (
        <>
          {" "}
          <Layout hasSider>
            <Drawer
              title="Close"
              placement={placement}
              width={500}
              onClose={onClose}
              open={open}
              autoFocus={false}
              className="responsive-drawer"
            >
              <div>
                <CategoryFilter
                  onCategoryChange={onCategoryChange}
                  isResetFilter={isResetFilter}
                />
                <Divider style={{ margin: "16px 0" }} />
                {maxPrice > 0 && (
                  <PriceFilter
                    onPriceSelected={onPriceSelected}
                    isResetFilter={isResetFilter}
                    maxPrice={maxPrice}
                  />
                )}
                <Divider style={{ margin: "16px 0" }} />
                <RatingFilter
                  onRatingSelected={onRatingSelected}
                  isResetFilter={isResetFilter}
                />
                <Divider style={{ margin: "16px 0" }} />
                <LocationFilter
                  onLocationChange={onLocationChange}
                  isResetFilter={isResetFilter}
                />
                <Divider style={{ margin: "16px 0" }} />
                <ColorFilter
                  onColorChange={onColorChange}
                  isResetFilter={isResetFilter}
                />
                <Divider style={{ margin: "16px 0" }} />
                <BrandFilter
                  brands={brands}
                  onBrandChange={onBrandChange}
                  isResetFilter={isResetFilter}
                />
                <Divider style={{ margin: "16px 0" }} />
                <Button
                  style={{
                    margin: "0 0 30px 0",
                    fontFamily: "AvenirNextLTPro",
                    width: "100%",
                  }}
                  onClick={resetFilters}
                >
                  Reset Filters
                </Button>
              </div>
            </Drawer>

            <Content style={contentStyle}>
              <ProductList
                fetchData={loadMoreProducts}
                isLoading={isLoading}
                products={filteredProducts}
                lastKey={lastKey}
              />
            </Content>
          </Layout>
          <div className="filter-btn-container">
            <div
              className={
                filteredProducts.length > 0
                  ? "filter-btn-container-header"
                  : "filter-btn-no-products-container"
              }
            >
              <Button className="filter-btn" onClick={showDrawer}>
                <Image
                  preview={false}
                  src={filter}
                  height={24}
                  width={24}
                  style={{ paddingRight: "5px" }}
                />
                Filters
              </Button>
              <Space>
                <Dropdown menu={menuProps}>
                  <Button className="sort-btn">
                    <Image
                      preview={false}
                      src={sortIcon}
                      style={{ paddingRight: "5px" }}
                    />
                    Sort
                  </Button>
                </Dropdown>
              </Space>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductsStore;
