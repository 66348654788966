import React from 'react'
import { Row, Col, Image } from 'antd'

//@ts-ignore
import vector17 from "../../assets/images/Vector17.svg"
//@ts-ignore
import vector18 from "../../assets/images/Vector18.svg"
//@ts-ignore
import vector19 from "../../assets/images/Vector19.svg"
//@ts-ignore
import vector20 from "../../assets/images/Vector20.svg"
//@ts-ignore
import vector21 from "../../assets/images/Vector21.svg"
//@ts-ignore
import vector22 from "../../assets/images/Vector22.svg"
import "./visionStyle/fifthSection.css"
//@ts-ignore
import vector42 from "../../assets/images/01_Sticker-with-zip-bag_By-Mocku.png"
//@ts-ignore
import vector43 from "../../assets/images/01-raw-tote-bag-mockup.png"
//@ts-ignore
import vector25 from "../../assets/images/blue scooty front 2.png"
//@ts-ignore
import vector26 from "../../assets/images/blue scooty front 3.png"
//@ts-ignore
import vector27 from "../../assets/images/blue scooty front 4.png"
//@ts-ignore
import vector28 from "../../assets/images/blue scooty front 5.png"
//@ts-ignore
import vector29 from "../../assets/images/blue scooty front 6.png"
//@ts-ignore
import vector44 from "../../assets/images/blue scooty front 7.png"
//@ts-ignore
import vector45 from "../../assets/images/extinguisher mockup 4.png"
//@ts-ignore
import vector46 from "../../assets/images/extinguisher mockup.png"
//@ts-ignore
import vector33 from "../../assets/images/Free Cap with Patch Mockup.png"
//@ts-ignore
import vector34 from "../../assets/images/Free Floating Business Card Mockup 2.png"
//@ts-ignore
import vector35 from "../../assets/images/Free Woman Holding Bottle Mockup.png"
//@ts-ignore
import vector36 from "../../assets/images/macbook-16-pro-mockup-on-concrete-background-front-view.png"
//@ts-ignore
import vector37 from "../../assets/images/Metallic Package.png"
//@ts-ignore
import vector38 from "../../assets/images/scooty wordmark.png"
//@ts-ignore
import vector39 from "../../assets/images/t shirt back mockup.png"
//@ts-ignore
import vector40 from "../../assets/images/Unmark_Hoodie_on _metal_3000 3cda.png"
//@ts-ignore
import vector41 from "../../assets/images/Wayfinding Wall Mockup.png"


const SixthSection = () => {
    return (
        <div className='vision-fifth-section-container'>
            <Row>
                <Col xs={24} md={12} lg={11} className=''>
                    <Image src={vector42} preview={false} className='vison-images'/>
                    <Image src={vector43} preview={false} className='vison-images'/>
                </Col>
                <Col xs={0} md={12} lg={12} className='vision-fifth-col-2'>
                    <Image src={vector40} preview={false} width={'525px'}/>
                </Col>
                <Col span={24} style={{padding:'20px'}}>
                    <Row>
                        <Col span={8}>
                            <Image src={vector25} preview={false} className='bottom-imgs' />
                        </Col>
                        <Col span={8}>
                            <Image src={vector26} preview={false} className='bottom-imgs' />
                        </Col>
                        <Col span={8}>
                            <Image src={vector27} preview={false} className='bottom-imgs' />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default SixthSection