import {
  Col,
  Typography,
  Space,
  Button,
  Radio,
  Image,
  Row,
  Breadcrumb,
  message,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getUserAddress } from "../../api/user";
import { useSelector } from "react-redux";

const { Text } = Typography;

const ResponsivePaymentSection = ({ onPaymentMethodChange, windowWidth }) => {
  const radioStyle = {
    display: "flex",
    alignItems: "left",
    justifyContent: "space-between",
    height: "30px",
    lineHeight: "30px",
  };
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [addressList, setAddressList] = useState([]);
  const [myAddress, setMyAddress] = useState(null);
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.auth.authToken);

  const handlePaymentChange = (e) => {
    setPaymentMethod(e.target.value);
    onPaymentMethodChange(e.target.value, myAddress);
  };

  // const handleOrderPlace = () => {
  //   if (!paymentMethod) {
  //     message.error("Please select payment method first");
  //   } else onOrderPlace(myAddress, paymentMethod);
  // };

  const getAndStoreUserAddress = async () => {
    const addressList = await getUserAddress();
    setAddressList(addressList);
    getAddressFromLocalStorage(addressList);
  };
  const getAddressFromLocalStorage = (addressList) => {
    const storedAddress = localStorage.getItem("selectedAddress");
    if (storedAddress && storedAddress !== "null") {
      const selectedAddress = JSON.parse(storedAddress);
      setMyAddress(selectedAddress);
    } else {
      if (addressList && addressList.length) {
        setMyAddress(addressList[0]);
        localStorage.setItem("selectedAddress", JSON.stringify(addressList[0]));
      }
    }
  };
  const generateAddressString = (addressObj) => {
    const getValueOrDefault = (obj, key) => (obj && obj[key]) || "";

    const formattedAddress = `${getValueOrDefault(
      addressObj,
      "firstName"
    )} ${getValueOrDefault(addressObj, "lastName")}\n${getValueOrDefault(
      addressObj,
      "address"
    )}, ${getValueOrDefault(addressObj, "city")}, ${getValueOrDefault(
      addressObj,
      "state"
    )}, ${getValueOrDefault(addressObj, "pincode")}\n${getValueOrDefault(
      addressObj,
      "countryRegion"
    )}`;

    return formattedAddress;
  };
  const checkUserLoggedIn = () => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    if (!otpless_user_token) {
      navigate("/eStore");
    }
  };
  useEffect(() => {
    checkUserLoggedIn();
    getAndStoreUserAddress();
  }, []);

  return (
    <Col xs={24} sm={24} md={10}>
      <div className="shipping-section">
        <Typography
          style={{ border: "1px solid #E3E3E3", borderRadius: '9px', padding: "15px", width: "100%" }}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #ccc",
                paddingBottom: "10px",
              }}
            >
              <div style={{ marginRight: "20px" }}>
                <Text type="secondary" style={{ fontFamily: 'AvenirNextLTPro', color: "#fff" }}>Contact</Text>
              </div>
              <div style={{ marginRight: "20px", flexGrow: 1 }}>
                <Text strong style={{ fontFamily: 'AvenirNextLTPro', color: "#fff" }}>{myAddress?.contact}</Text>
              </div>
              <div>
                <Link to={"/checkout/address"}>
                  <Text
                    strong
                    style={{ whiteSpace: "nowrap", color: "#00ffff", fontFamily: 'AvenirNextLTPro' }}
                  >
                    Change
                  </Text>
                </Link>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ marginRight: "20px" }}>
                <Text type="secondary" style={{ whiteSpace: "nowrap", fontFamily: 'AvenirNextLTPro', color: "#fff" }}>
                  Ship to
                </Text>
              </div>
              <div style={{ marginRight: "20px", flexGrow: 1 }}>
                <Text strong style={{ fontFamily: 'AvenirNextLTPro', color: "#fff" }}>{generateAddressString(myAddress)}</Text>
              </div>
              <div>
                <Link to={"/checkout/address"}>
                  <Text
                    strong
                    style={{ whiteSpace: "nowrap", color: "#04B2A9", fontFamily: 'AvenirNextLTPro', color: "#fff" }}
                  >
                    Change
                  </Text>
                </Link>
              </div>
            </div>
          </Space>
        </Typography>
        <Typography
          style={{ fontSize: "25px", paddingTop: "15px", fontWeight: "bold", fontFamily: 'AvenirNextLTPro', color: "#fff" }}
        >
          Payment Method
        </Typography>
        <Typography style={{ color: "#fff", paddingTop: "7px", fontFamily: 'AvenirNextLTPro' }}>
          All Transaction are secure and encrypted
        </Typography>
        <Typography
          style={{
            width: "95%",
            margin: "20px 0 40px 0",
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row>
              <Col span={24}>
                <Radio.Group
                  onChange={handlePaymentChange}
                  value={paymentMethod}
                  style={{ display: "block" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center", // Vertically center the content
                      border: "1px solid #E3E3E3",
                      padding: "10px",
                      borderRadius: "9px",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    <Radio
                      value="online"
                      className="payment-radio"
                      style={{ fontFamily: "AvenirNextLTPro", color: "#fff" }}
                    >
                      Debit / Credit / UPI
                    </Radio>
                  </div>
                  {/* <div
                    style={{
                      border: "1px solid #E3E3E3",
                      padding: "10px",
                      borderRadius: "9px",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    <Radio
                      value="decentro"
                      className="payment-radio"
                      style={{ fontFamily: "AvenirNextLTPro" }}
                    >
                      Decentro Payment Gateway
                    </Radio>
                  </div> */}
                  <div
                    style={{
                      border: "1px solid #E3E3E3",
                      padding: "10px",
                      borderRadius: "9px",
                      width: "100%",
                    }}
                  >
                    <Radio value="COD" className="payment-radio" style={{ fontFamily: 'AvenirNextLTPro' }}>Cash on Delivery (COD)</Radio>
                  </div>
                </Radio.Group>
              </Col>
            </Row>
          </Space>
        </Typography>
      </div>
    </Col>
  );
};

export default ResponsivePaymentSection;
