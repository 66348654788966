import React from 'react'
import './style.css'

const Performance = ({ productData }) => {
    return (
        <table className='overview-table'>
            <tr>
                <td>&#9679; Top Speed</td>
                <td>{productData.topSpeed_Overview ? `${productData.topSpeed_Overview} km/h` : "-"}</td>
            </tr>
            <tr>
                <td>&#9679; Range</td>
                <td>{productData.range_Overview ? `${productData.range_Overview} km` : "-"}</td>
            </tr>
            <tr>
                <td>&#9679; Driving Modes</td>
                <td>{productData.driveModes ? productData.driveModes : "-"}</td>
            </tr>
            <tr>
                <td>&#9679; Motor Type</td>
                <td>{productData.motorType ? productData.motorType : "-"}</td>
            </tr>
            <tr>
                <td>&#9679; Transmission</td>
                <td>{productData.transmission_Overview ? productData.transmission_Overview : "-"}</td>
            </tr>
            <tr>
                <td>&#9679; Battery Type</td>
                <td>{productData.batteryType ? productData.batteryType : "-"}</td>
            </tr>
            <tr>
                <td>&#9679; Battery Capacity</td>
                <td>{productData.batteryCapacity ? productData.batteryCapacity : "-"}</td>
            </tr>
            <tr>
                <td>&#9679; Charger Type and Capacity</td>
                <td>{productData.chargerType ? productData.chargerType : "-"}</td>
            </tr>
            <tr>
                <td>&#9679; Peak Motor Power</td>
                <td>{productData.peakMotorPower ? `${productData.peakMotorPower} kW` : "-"}</td>
            </tr>
            <tr>
                <td>&#9679; Motor Power</td>
                <td>{productData.motorPower ? `${productData.motorPower} kW` : "-"}</td>
            </tr>
        </table>
    )
}

export default Performance