import './style.css'
import { Col, Row, Typography } from 'antd'
const { Text } = Typography;

export const OtherDetails = ({ name, category, brand, mrp, color }) => {
    return (
        <>
            <div className='other-details-table'>
                <Text className='product-description-header'>Specification:</Text>
                <Row style={{ padding: '5px 0', marginTop: '10px' }}>
                    <Col xs={12} sm={10} md={10} lg={4}>
                        <Text className='product-specifications'>&#9679; Product Name</Text>
                    </Col>
                    <Col xs={12} sm={14} md={14} lg={4}>
                        <Text className='product-specifications'>{name}</Text>
                    </Col>
                </Row>
                <Row style={{ padding: '5px 0' }}>
                    <Col xs={12} sm={10} md={10} lg={4}>
                        <Text className='product-specifications'>&#9679; Product Category</Text>
                    </Col>
                    <Col xs={12} sm={14} md={14} lg={4}>
                        <Text className='product-specifications'>{category}</Text>
                    </Col>
                </Row>
                <Row style={{ padding: '5px 0' }}>
                    <Col xs={12} sm={10} md={10} lg={4}>
                        <Text className='product-specifications'>&#9679; Product Brand</Text>
                    </Col>
                    <Col xs={12} sm={14} md={14} lg={4}>
                        <Text className='product-specifications'>{brand}</Text>
                    </Col>
                </Row>
                <Row style={{ padding: '5px 0' }}>
                    <Col xs={12} sm={10} md={10} lg={4}>
                        <Text className='product-specifications'>&#9679; MRP</Text>
                    </Col>
                    <Col xs={12} sm={14} md={14} lg={4}>
                        <Text className='product-specifications'>₹ {mrp}</Text>
                    </Col>
                </Row>
                <Row style={{ padding: '5px 0' }}>
                    <Col xs={12} sm={10} md={10} lg={4}>
                        <Text className='product-specifications'>&#9679; Color</Text>
                    </Col>
                    <Col xs={12} sm={14} md={14} lg={4}>
                        <Text className='product-specifications'>{color.split(',').join(', ')}</Text>
                    </Col>
                </Row>
            </div>
        </>
    )
}
