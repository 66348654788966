import React, { useEffect, useState } from "react";
import { Row, message, Typography, Col, Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PaymentSection from "../Components/CheckOut/PaymentSection";
import OrderSummary from "../Components/CheckOut/OrderSummary";
import uuid from "react-uuid";
import {
  createOrder,
  createRazorpayOrder,
  validatePayment,
} from "../api/order";
import { CollectionUIComponent } from "@decentro1/collections-payment-sdk-web";
import { useNavigate } from "react-router-dom";
import { deleteAllProductFromCart, getUserCartList } from "../api/user";
import OrderPlacedModal from "../modals/OrderPlacedModal";
import { updateCartItem } from "../Redux/actionTypes";
import ResponsivePaymentSection from "../Components/CheckOut/ResponsivePaymentSection";
import ResponsiveOrderSummary from "../Components/CheckOut/ResponsiveOrderSummary";
import { LeftOutlined } from "@ant-design/icons";

const ChosePaymentSection = () => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(false);
  const authState = useSelector((state) => (state ? state.auth : []));
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [addressData, setAddressData] = useState(null);
  const [userAddress, setUserAddress] = useState();
  const [userPaymentMethod, setUserPaymentMethod] = useState();
  const [isDecentroPayment, setIsDecentroPayment] = useState(false)
  const [linkAPIResponse, setlinkAPIResponse] = useState(null);
  const [paymentAPIResponse, setPaymentAPIResponse] = useState(null);
  const [upiValidateAPIResponse, setUpiValidateAPIResponse] = useState(null);
  const [collectionAPIResponse, setCollectionAPIResponse] = useState(null);
  const { Text } = Typography;
  const dispatch = useDispatch();
  const urlHeader = {
    client_id: "lilypad_prod", //<your_client_id>
    client_secret: "JBIvHEX3xKMnUJrGpTL0rLSLlIYVrzJu", // <your_client_secret>
    module_secret: "OyzFeOeoPgc7rCyBq5t31QmJ4fLdBsA3", // <your_module_secret>
    provider_secret: "ZerzHGaKMdcnrzPkdx2jmmlzTDG7DcTa", // <YOUR_PROVIDER_SECRET>
  };

  // API body


  const onModalClose = () => {
    setIsModalOpen(false);
  };
  const navigate = useNavigate();

  const onPaymentMethodChange = (value, myAddress) => {
    setUserPaymentMethod(value);
    setUserAddress(myAddress);
  };

  const handleOrderPlace = () => {
    if (!userPaymentMethod) {
      message.error("Please select payment method first");
    } else onOrderPlace(userAddress, userPaymentMethod);
  };

  const uuidFromReactUUID = () => {
    const newUuid = uuid()
    return newUuid;
  }
  const onOrderPlace = async (address, paymentMethod) => {
    setIsLoading(true);
    const deliveryDate = new Date();
    deliveryDate.setDate(deliveryDate.getDate() + 3);
    setAddressData(address);
    const payload = {
      address_id: address.id,
      order_items: cartItems,
      amount: totalAmount,
      payment_mode: paymentMethod,
      status: "Placed",
      customerName: authState.userDetails?.full_name || "",
      customerEmail: authState.userDetails?.email || "",
      estimated_delivery_date: deliveryDate,
    };
    updatePixelEvent(cartItems, paymentMethod);
    if (paymentMethod === "online") {
      const customerInfo = {
        contact_name: authState.userDetails.full_name,
        contact_type: "customer",
        shipping_address: {
          address: address.address,
          city: address.city,
          state: address.state,
          phone: address.contact,
        },
      };
      let { data } = await createRazorpayOrder(payload);
      if (data) {
        let options = {
          key: process.env.REACT_APP_RAZORPAY_TEST_KEY,
          amount: payload.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Lilypad Tech Private Limited",
          description: "Please select your desired payment method",
          order_id: data.razorpayOrder.id,
          modal: {
            ondismiss: function () {
              message.error("Your payment was dismissed!");
              navigate("/cart");
            },
          },
          handler: function (response) {
            payload.paymentStatus = "success";
            payload.razorpay_order_id = response.razorpay_order_id;
            payload.razorpay_payment_id = response.razorpay_payment_id;
            payload.razorpay_signature = response.razorpay_signature;
            validatePayment(payload).then((validateResp) => {
              if (validateResp.data.error === false) {
                createOrder(payload).then(async ({ data }) => {
                  deleteAllProductFromCart().then((response) => {
                    getUserCartList().then((response) => {
                      dispatch(updateCartItem(response));
                      // setIsModalOpen(true);
                      setIsLoading(false);
                      navigate(`/order-success`);
                    });
                  });
                });
              } else {
                payload.paymentStatus = "failed";
                createOrder(payload).then(async ({ data }) => {
                  deleteAllProductFromCart().then((response) => {
                    getUserCartList().then((response) => {
                      dispatch(updateCartItem(response));
                      // setIsModalOpen(true);
                      setIsLoading(false);
                      navigate(`/cart`);
                    });
                  });
                });
                message.error(
                  "Sorry something failed while validating your payment!"
                );
              }
            });
          },
          
          prefill: {
            //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
            name: data.customerName, //your customer's name
            email: data.customerEmail,
            contact: "", //Provide the customer's phone number for better conversion rates
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        const rzpayInstance = new window.Razorpay(options);

        rzpayInstance.on("payment.failed", function (response) {
          alert("Your payment has failed! please try again");
        });
        rzpayInstance.open();
      }
    } else if (paymentMethod === "decentro") {

      setIsDecentroPayment(true);
    } else {
      createOrder(payload).then(async ({ data }) => {
        deleteAllProductFromCart().then((response) => {
          getUserCartList().then((response) => {
            dispatch(updateCartItem(response));
            // setIsModalOpen(true);
            setIsLoading(false);
            navigate(`/order-success`);
          });
        });
      });
    }
  };

  const updatePixelEvent = (cartItems, paymentMethod) => {
    window.fbq('track', 'orders', {
      content_cart: cartItems,
      content_payment: paymentMethod,
      content_type: 'purchase order',
      value: totalAmount * 100,
      currency: 'INR'
     });
  };

  const getOrderTotalAmount = (amount) => {
    const amountData = Math.round(amount);
    setTotalAmount(amountData);
  };

  // const getTotalAmount = (cartItems) => {
  //   let totalAmount = 0;
  //   if (cartItems.length) {
  //     cartItems.forEach((item) => {
  //       totalAmount += item.sellingPrice * item.count;
  //     });
  //   }
  //   setTotalAmount(totalAmount);
  //   if (totalAmount >= 5000) {
  //     setShippingCharge(0);
  //   } else {
  //     setShippingCharge(50);
  //   }
  // };

  const checkUserLoggedIn = () => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    if (!otpless_user_token) {
      navigate("/products");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // Event handler to update windowWidth state
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    const handleReload = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);
    window.addEventListener("reload", handleReload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("reload", handleReload);
    };
  }, [windowWidth]);

  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  useEffect(() => {
    if (paymentAPIResponse?.data?.transactionStatus === "PENDING" || paymentAPIResponse?.status === "FAILURE") {
      return;
    }
    if (paymentAPIResponse) {
      const paymentDataStatus = paymentAPIResponse?.data?.transactionStatus === "SUCCESS" ? true : false;
      const deliveryDate = new Date();
      deliveryDate.setDate(deliveryDate.getDate() + 3);
      const payload = {
        address_id: addressData?.id,
        order_items: cartItems,
        amount: totalAmount,
        payment_mode: "Decentro Payment Gateway",
        status: "Placed",
        customerName: authState.userDetails?.full_name || "",
        customerEmail: authState.userDetails?.email || "",
        estimated_delivery_date: deliveryDate,
      };
      if (paymentDataStatus) {
        payload.paymentStatus = paymentAPIResponse.data.transactionStatus === "SUCCESS" ? "success" : "failed";
        payload.decentro_npciTxnId_id = paymentAPIResponse.data.npciTxnId;
        payload.decentro_txn_id = paymentAPIResponse.decentroTxnId;
        createOrder(payload).then(async ({ data }) => {
          deleteAllProductFromCart().then((response) => {
            getUserCartList().then((response) => {
              dispatch(updateCartItem(response));
              setIsLoading(false);
              navigate(`/order-success`);
            });
          });
        });
        setIsDecentroPayment(false);
      } else {
        payload.paymentStatus = "failed";
        createOrder(payload).then(async ({ data }) => {
          deleteAllProductFromCart().then((response) => {
            getUserCartList().then((response) => {
              dispatch(updateCartItem(response));
              setIsLoading(false);
              navigate(`/cart`);
            });
          });
        });
        message.error(
          "Sorry something failed while validating your payment!"
        );

        setIsDecentroPayment(false);
      }
    }
  }, [paymentAPIResponse])


  return (
    isDecentroPayment ? <CollectionUIComponent
      urlHeaderData={urlHeader}
      urlBodyData={
        {
          reference_id: uuidFromReactUUID(), //unique transaction id
          payee_account: "D86063459293111512", //payee account number
          amount: totalAmount, // amount to be deducted
          purpose_message: "String", // payment message
          generate_qr: 1, // 0 = do not generate QR , 1 = to generate QR
          expiry_time: 10, //expiry time for payment
          customized_qr_with_logo: 0, //logo based QR then pass 1
          generate_uri: 1, // to generate the deep links 
        }}
      linkGenerationAPIRes={setlinkAPIResponse} //will return api response for generate payment link
      gtsStatusAPIRes={setPaymentAPIResponse} //will return api response for check payment status
      upiValidateAPIRes={setUpiValidateAPIResponse} //will return api response for validate UPI ID
      collectionReqAPIRes={setCollectionAPIResponse} //will return api response for issue collection request
    /> : <>
      <div
        onClick={() => navigate(-1)}
        style={{ margin: '10px 20px', display: 'flex', width: '140px' }}
      >
        <Text className="back-btn-text" style={{ height: '40px' }}>
          {' '}
          <LeftOutlined className="product-detail-back-btn-icon" />
          Back
        </Text>
      </div>

      {windowWidth > 500 ? (
        <>
          <Row
            gutter={16}
            type="flex"
            justify="center"
            align="middle"
            style={{ margin: 0 }}
          >
            <PaymentSection
              onOrderPlace={onOrderPlace}
              isLoading={isLoading}
              windowWidth={windowWidth}
            />
            <OrderSummary getOrderTotalAmount={getOrderTotalAmount} />

          </Row>
        </>
      ) : (
        <>
          <Row gutter={16} type="flex" justify="center" align="middle">
            {/* here we need to add responsive screen */}
            <ResponsivePaymentSection
              onPaymentMethodChange={onPaymentMethodChange}
              windowWidth={windowWidth}
            />
            <OrderSummary getOrderTotalAmount={getOrderTotalAmount} />
            <Col xs={24} style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0px" }}
            >
              <Button className="gradient-btn"
                onClick={() => handleOrderPlace()}
                disabled={isLoading}
              >
                {isLoading ? <Spin /> : (userPaymentMethod ? "Place order" : "Continue To Payment")}
              </Button>
            </Col>
          </Row>
        </>
      )}
      <OrderPlacedModal isModalOpen={isModalOpen} onModalClose={onModalClose} />
    </>
  );
};

export default ChosePaymentSection;
