import React, { useState, useEffect } from 'react';
import './userStyle/style.css';
import { Button, Col, Row, Card, Layout, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { deleteAddress, getUserAddress } from '../../api/user';
import { generateAddressString } from '../../utils/user';
import { PlusOutlined } from '@ant-design/icons';
import { Address } from '../../Components/Address';
import { useDispatch, useSelector } from 'react-redux';
import {
	setDeletedAddress,
	setAddresses,
} from '../../Redux/actionTypes/authAction';
const { Text, Title } = Typography;

const AddressPage = () => {
	const addressState = useSelector((state) => {
		return state ? state.auth.addresses : [];
	});
	const [addressList, setAddressList] = useState([]);
	const [selectedAddress, setSelectedAddress] = useState(null);
	const [isNewAddressAdd, setIsNewAddressAdd] = useState(false);
	const dispatch = useDispatch();

	const addAddressStyle = {
		height: 260,
		margin: '10px 0',
		border: '1px dashed #848397',
		borderRadius: 6,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	};

	const checkUserLoggedIn = () => {
		const otpless_user_token = JSON.parse(
			localStorage.getItem('otpless_user_token')
		);
	};

	useEffect(() => {
		checkUserLoggedIn();
	}, []);

	const getAndStoreUserAddress = async () => {
		if (addressState.length > 0) {
			setAddressList(addressState);
		} else {
			const addressList = await getUserAddress();
			dispatch(setAddresses(addressList));
			setAddressList(addressList);
		}
	};

	useEffect(() => {
		getAndStoreUserAddress();
	}, [addressState]);

	const onEdit = (address) => {
		setIsNewAddressAdd(true);
		setSelectedAddress(address);
	};

	const onDelete = (payload) => {
		deleteAddress(payload).then((response) => {
			dispatch(setDeletedAddress(response));
		});
	};

	return (
		<Layout className="layout-section">
			<Title className="title-text">
				{isNewAddressAdd ? 'Add address' : 'Your Address'}
			</Title>
			{isNewAddressAdd ? (
				<Address
					setIsNewAddressAdd={setIsNewAddressAdd}
					selectedAddress={selectedAddress}
				/>
			) : (
				<Row gutter={16}>
					<Col
						xs={24}
						sm={24}
						md={12}
						lg={8}
						xl={8}
						style={{ padding: '0 10px' }}
					>
						<Card
							style={addAddressStyle}
							hoverable
							onClick={() => setIsNewAddressAdd(true)}
						>
							<div style={{ marginLeft: 20, paddingBottom: 5 }}>
								<PlusOutlined style={{ fontSize: '32px', color: '#fff' }} />
							</div>
							<Text
								style={{
									fontFamily: 'AvenirNextLTPro',
									fontWeight: 600,
									color: '#fff',
								}}
							>
								Add address
							</Text>
						</Card>
					</Col>
					{addressList.map((address) => (
						<Col xs={24} sm={24} md={12} lg={8} xl={8} key={address.id}>
							<Card
								bordered={true}
								className="address-card"
								style={{ height: '260px' }}
							>
								<div>
									<Text className="address-card-name" style={{ color: '#fff' }}>
										{`${address.firstName} ${address.lastName}`}
									</Text>
								</div>
								<div style={{ margin: '10px 0' }}>
									<Text
										className="address-card-address-details"
										style={{ color: '#fff' }}
									>
										{generateAddressString(address, false)}
									</Text>
								</div>
								<div>
									<Text
										className="address-card-address-details"
										style={{ color: '#fff' }}
									>
										Phone : {address.phone}
									</Text>
								</div>
								<div>
									<Text
										className="address-card-address-details"
										style={{ color: '#fff' }}
									>
										Email : {address.contact}
									</Text>
								</div>
								<div className="address-card-edit-delete-container">
									<Row gutter={[16]}>
										<Col>
											<Text
												className="address-card-edit-delete-button"
												onClick={() => onEdit(address)}
												style={{ color: '#fff' }}
											>
												Edit
											</Text>
										</Col>
										<span style={{ color: '#f2f2f2', marginTop: '-3px' }}>
											|
										</span>
										<Col>
											<Text
												className="address-card-edit-delete-button"
												onClick={() => onDelete({ id: address.id })}
												style={{ color: '#fff' }}
											>
												Remove
											</Text>
										</Col>
									</Row>
								</div>
							</Card>
						</Col>
					))}
				</Row>
			)}
		</Layout>
	);
};

export default AddressPage;
