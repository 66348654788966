import { message } from "antd";
import authApiService from "./authApiService";
import productApiService from "./productApiService";

// GET All product
export const getAllProduct = async (queryParams) => {
  if (queryParams) {
    // const products = await productApiService.get(`product?offset=${offset}`);
    const products = await productApiService.get(
      `product?city=${queryParams.city}&district=${queryParams.district}&state=${queryParams.state}`
    );
    return products?.data;
  } else {
    const products = await productApiService.get(`product`);
    return products?.data;
  }
  // ?city=${}&district=${}&state=${}
  // products.data = products?.data?.data?.filter(product => product.isPublished);
};

export const getFiltered = async (queryParams, filters) => {
  try {
    const { data } = await productApiService.post(
      `/product/filter?city=${queryParams.city}&district=${queryParams.district}&state=${queryParams.state}&lastKey=${queryParams.lastKey}`,
      filters
    );
    return data;
  } catch (error) {
    message.error("Failed to fetch products!");
  }
};
// GET product by id
export const getProduct = async (productId) => {
  return await productApiService.get(`product/${productId}`);
};
export const createReview = async (userReview) => {
  try {
    const review = await productApiService.patch("/product/review", userReview);
    return review;
  } catch (error) {
    // Handle the error here, log it, and return an empty array
    // console.error("Error writing review:", error);
    return {}; // Return an empty array in case of failure
  }
};

export const createProduct = async (productData) => {
  return productApiService.post("product", productData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const saveCustomerToZoho = async (customerInfo) => {
  const response = await productApiService.post(
    "/product/zoho/save-customer",
    customerInfo
  );
  return response.data.data;
};

export const getAllBrands = async () => {
  try {
    const response = await authApiService.get("/auth/brands");
    return response.data.data;
  } catch (error) {
    // Handle the error here, you can log it or perform other actions as needed
    // console.error("Error fetching vendor list:", error);
    return [];
  }
};

export const getDealersByPincode = async (payload) => {
  const get_dealers_by_pincode = await productApiService.post(
    "/product/getDealersByPincode",
    payload
  );
  return get_dealers_by_pincode;
};

export const getAllCategories = async () => {
  try {
    const response = await productApiService.get("/category");
    return response.data.data;
  } catch (error) {
    // Handle the error here, you can log it or perform other actions as needed
    // console.error("Error fetching vendor list:", error);
    return [];
  }
};