const initialState = {
    products: [],
    brands: [],
    categories: [],
    colors: [],
    maxPrice: 100000
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_PRODUCTS":
            return {
                ...state,
                products: action.payload,
            };
        case "SET_BRANDS":
            return {
                ...state,
                brands: action.payload,
            };
        case "SET_CATEGORIES":
            return {
                ...state,
                categories: action.payload,
            };
        case "SET_PRODUCT_COLORS":
            return {
                ...state,
                colors: action.payload,
            };
        case "SET_PRODUCT_MAX_PRICE":
            return {
                ...state,
                maxPrice: action.payload,
            };
        default:
            return state;
    }
};

export default productReducer;
