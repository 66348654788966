import vector5 from "../../src/assets/images/Vector5.svg";
import '../Components/ProductDetail/productdetailStyle/productDetails.css'
import { ArrowLeftOutlined, LeftOutlined, RightOutlined, UploadOutlined } from "@ant-design/icons";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { FaChevronLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import {
  Row,
  Col,
  Input,
  Typography,
  Form,
  Select,
  Button,
  Flex,
  Upload,
  Image,
  message,
  Radio,
  Carousel,
  Rate,
  Divider,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import authApiService from "../api/authApiService";
import { bookDemo } from "../api/order";
import { getProduct } from "../api/product";
import { formatToRupees } from "../utils/formatToRupees";

const contentStyle = {
  margin: 0,
  height: "100%",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  width: "230px",
};

const closeButton = {
  background: "linear-gradient(183.98deg, #b0ffff 2.37 %, #00ffff 95.82 %)",
  color: "#000000",
  height: "44px",
  width: 130,
  cursor: 'pointer'
}

const submitButton = {
  background: "linear-gradient(183.98deg, #b0ffff 2.37 %, #00ffff 95.82 %)",
  color: "#000000",
  height: "44px",
  width: 130,
  cursor: 'pointer'
}

const BookYourDemo = () => {
  const [searchParams] = useSearchParams();
  const { Title, Text } = Typography;
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dealerLocation, setDealerLocation] = useState([]);
  const [dealerData, setDealerData] = useState([]);
  const [value, setValue] = useState(null);
  const [demoType, setDemoType] = useState(null);
  const [averageRating, setAverageRating] = useState(0);
  const [addressInfoError, setAddressInfoError] = useState(null)
  const [idInfoError, setIdInfoError] = useState(null)
  const [productImages, setProductImages] = useState([])
  const [imgIndex, setImgIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();
  const navigate = useNavigate()
  // let productImages = [];
  const productId = searchParams.get("productId");
  const vendorId = searchParams.get("vendorId");
  const pincode = searchParams.get("pincode");

  const handleReset = () => {
    setProductImages([])
    setValue(null);
    form.resetFields();
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const carouselRef = React.createRef();

  const onSlideChange = (currentSlide) => {
    // 
  };
  let imageUrls = product ? product?.productImages?.split(",") : [vector5];

  const handleNext = () => {
    if (imgIndex < imageUrls.length - 1) {
      setImgIndex(imgIndex + 1);
    }
  };

  const handlePrev = () => {
    if (imgIndex > 0) {
      setImgIndex(imgIndex - 1);
    }
  };

  const customRequest = async ({ file, onSuccess, onError, propName }) => {
    if (!file) {
      // console.error("Please select a file.");
      return;
    }

    const bucketName = process.env.REACT_APP_AWS_S3_PRODUCT_IMAGE_BUCKET;
    const bucketRegion = process.env.REACT_APP_AWS_S3_BUCKET_REGION;
    const awsAccessKey = process.env.REACT_APP_AWS_LILYPAD_PRODUCTS_ACCESS_KEY;
    const awsSecretKey = process.env.REACT_APP_AWS_LILYPAD_PRODUCTS_SECRET_KEY;
    const s3Client = new S3Client({
      region: bucketRegion,
      credentials: {
        accessKeyId: awsAccessKey,
        secretAccessKey: awsSecretKey,
      },
    });

    // Set the S3 parameters
    const params = {
      Bucket: bucketName,
      Key: file.name,
      Body: file,
      ContentType: file.type,
    };

    try {
      const command = new PutObjectCommand(params);
      const response = await s3Client.send(command);

      onSuccess({ url: response.Location });
      message.success("File uploaded successfully.");
      let bucketBaseUrl = process.env.REACT_APP_AWS_BUCKET_BASE_URL;
      let fileUrl = `${bucketBaseUrl}/${encodeURIComponent(file.name)}`;

      const imageObject = {
        url: fileUrl,
        source: propName
      };
      // productImages.push(imageObject);
      setProductImages([...productImages, imageObject])

      return fileUrl;
    } catch (error) {
      // console.error("Error uploading file to S3:", error);
      onError(error);
      message.error("File upload failed.");
    }
  };

  const addressInfoProp = {
    name: "addressInfo",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      // e.preventDefault();
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setAddressInfoError("")
        // console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // console.log(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: (options) => customRequest({ ...options, propName: 'addressInfo' }),
  };

  const idInfoProp = {
    name: "idInfo",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      // e.preventDefault();
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setIdInfoError("")
        // console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // console.log(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: (options) => customRequest({ ...options, propName: 'idInfo' }),
  };

  const getVendorInfo = async () => {
    try {
      const { data } = await authApiService.get(`/auth/vendor/${vendorId}`);
      if (data) {
        // var dealers = data.data.dealers.filter(
        //   (obj) => obj.location.pincode == pincode
        // );
        var dealers = data.data.dealers
        var both_demo = dealers.find((obj) => obj.demoType === "BOTH");
        var home_demo = dealers.find(
          (obj) => obj.demoType === "HOME_DEMO_ONLY"
        );
        var dealer_demo = dealers.find(
          (obj) => obj.demoType === "DEALER_DEMO_ONLY"
        );

        if (both_demo) {
          setDemoType("BOTH");
        } else if (home_demo && dealer_demo) {
          setDemoType("BOTH");
        } else if (home_demo) {
          setDemoType("HOME_DEMO_ONLY");
        } else if (dealer_demo) {
          setDemoType("DEALER_DEMO_ONLY");
        }

        const dealer_locations = dealers.filter(
          (dealer) => dealer.demoType === "DEALER_DEMO_ONLY" || dealer.demoType === "BOTH"
        ).map((dealer) => ({
          demoType: dealer.demoType,
          label: `${dealer.address1} ${dealer.address2} ${dealer.city}`,
          value: `${dealer.address1} ${dealer.address2} ${dealer.city}`,
        }));

        setDealerData(dealers);
        setDealerLocation(dealer_locations);
      }
    } catch (error) {
      // 
    }
  };

  const handleSubmit = async (body) => {
    const idProofFile = productImages.find(image => image.source === 'idInfo');
    const addressInfoFile = productImages.find(image => image.source === 'addressInfo');
    // if (!addressInfoFile || !idProofFile) {
    //   setAddressInfoError(!addressInfoFile ? "Please upload address proof document" : "");
    //   setIdInfoError(!idProofFile ? "Please upload ID proof document" : "");
    //   return;
    // }
    // else {
      updatePixelEvent(body)
      console.log("Booking Demo for the product");
      setIsLoading(true);
      body.addressProof = addressInfoFile?.url
      body.IDproof = idProofFile?.url
      body.vendorId = vendorId;
      body.productId = productId;
      body.dealerLocation = dealerLocation.length > 0 ? dealerLocation[0].value : "";
      value ? (body.demoType = value) : (body.demoType = demoType);

      try {
        const { data } = await bookDemo(body);
        if (data) {
          message.success("Demo request submitted successfully");
          setIsLoading(false);
          navigate(`/booking-success`);
          form.resetFields();
        }
      } catch (error) {
        message.error("Failed to send demo request!");
        setIsLoading(false);
      }
      setIsLoading(false);
      form.resetFields();
      setProductImages([])
      setValue(null)
    // }
  };

  const updatePixelEvent = (body) => {
    window.fbq('track', 'demobooking', {
      content_details: body,
      content_type: 'Book A Demo'
     });
  };

  const onChange = (e) => {
    setDealerLocation(
      dealerLocation.filter(
        (obj) => obj.demoType === "BOTH" || obj.demoType === e.target.value
      )
    );
    setValue(e.target.value);
  };

  const getProductDetails = async (productId) => {
    try {
      const response = await getProduct(productId);
      if (response?.data?.reviews?.length > 0) {
        const reviews = response?.data?.reviews
        const rating_count = reviews.map((e) => e.rating)
        const total_rating = rating_count.reduce((a, b) => a + b, 0);
        const average_rating = (total_rating / rating_count.length) || 0;
        setAverageRating(average_rating)
      }
      setProduct(response?.data);
      setIsLoading(false)
    } catch (error) {
      // console.error("Error fetching product details:", error);
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getVendorInfo();
    if (productId) {
      getProductDetails(productId)
    }
  }, []);

  console.log(demoType)
  return (
    <>
      {product && (
        <Row style={{ padding: "20px 0px" }}>
          <Col>
            <Form
              onFinish={handleSubmit}
              form={form}
              name="book_demo_form"
              layout="vertical"
            >
              <Row align={"top"} justify={"center"} className="book-demo-form-container">

                <Col span={20} xs={24} sm={24} md={20} lg={24} xl={24} xxl={24} style={{ maxWidth: "900px", }}>

                  <Flex gap={'small'} className="product-detail-back-container" style={{ alignItems: "center" }}>
                    <div className="product-detail-back-btn" onClick={() => navigate(-1)}>

                      <Text className="back-btn-text"> <LeftOutlined className="product-detail-back-btn-icon" style={{ color: "#000000" }} />Back</Text>
                    </div>
                    <Title style={{ fontFamily: 'AvenirNextLTPro', color: "#fff" }} level={2} className="book-demo-title">Book your demo</Title>
                  </Flex>

                  <Row gutter={16} type="flex" justify="center" align="middle" style={{ margin: '0' }}>
                    <Col xs={24} sm={24} md={24} lg={10}>
                      <Row gutter={16} >
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          className="left-arrow-alignment"
                          onClick={handlePrev}
                        >
                          <FaChevronLeft style={{ fontSize: "24px", cursor: "pointer", color: "#fff" }} />
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16}>
                          <div className="left-div-border">
                            {/* <Carousel ref={carouselRef} afterChange={onSlideChange} dots={false}>
                              {product?.productImages?.split(",")?.length > 0 &&
                                product?.productImages?.split(",").map((url, index) => ( */}
                            <div className="image-alignment">
                              <img
                                src={product?.productImages?.split(",")[imgIndex] || vector5}
                                className="book-demo-product-image"
                                alt={"product-img"}
                                style={{
                                  ...contentStyle,
                                }}
                              />
                            </div>
                            {/* ))}
                            </Carousel> */}
                          </div>
                        </Col>
                        <Col
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          className="right-arrow-alignment"
                          onClick={handleNext}
                        >
                          <FaAngleRight style={{ fontSize: "24px", cursor: "pointer", color: "#fff" }} />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={22} sm={22} md={24} lg={14}>
                      <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <Title style={{ fontFamily: 'AvenirNextLTPro', color: "#fff" }} level={2}>{product?.name}</Title>
                          <div style={{ marginTop: '10px' }}>
                            <Row>
                              <Col style={{ marginLeft: '5px' }}>
                                <Text className="cost">
                                  ₹ {formatToRupees(product?.sellingPrice)}
                                </Text>
                              </Col>
                              <Col style={{ marginTop: '5px' }}>
                                <Text className="actual-cost" delete>
                                  ₹ {formatToRupees(product?.productPrice)}
                                </Text>
                              </Col>
                              <Col style={{ marginTop: '5px', marginLeft: '20px' }}>
                                <div className="discount-value">
                                  <p style={{ marginTop: '17px' }}>
                                    {product?.discount}% off
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <div style={{ marginTop: '13px' }}>
                            <Rate
                              allowHalf={true}
                              value={product?.ratings}
                              style={{
                                fontSize: 22,
                                color: "gold",
                                marginRight: "16px",
                                paddingTop: "10px",
                                color: '#FF902D'
                              }}
                              disabled={true}
                            />
                            <span style={{ fontSize: 18, color: "gray", fontFamily: 'AvenirNextLTPro' }}>{`(${product?.reviews?.length} Reviews)`}</span>
                          </div>
                        </Col>
                        <Divider />
                        <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: '20px' }}>
                          <Row className="book-demo-product-specification">
                            <Col span={6}>
                              <Text className="product-specification-value">
                                {product?.range_Overview} km
                              </Text>
                              <div>
                                <Text className="product-specification-text">
                                  Range
                                </Text>
                              </div>
                            </Col>
                            <Col span={6}>
                              <Text className="product-specification-value">
                                {product?.topSpeed_Overview} km/h
                              </Text>
                              <div>
                                <Text className="product-specification-text">
                                  Top Speed
                                </Text>
                              </div>
                            </Col>
                            <Col span={6}>
                              <Text className="product-specification-value">
                                {product?.chargingTime} hours
                              </Text>
                              <div>
                                <Text className="product-specification-text">
                                  Charging time
                                </Text>
                              </div>
                            </Col>
                            <Col span={6}>
                              <Text className="product-specification-value">
                                {product?.motorPower} kW
                              </Text>
                              <div>
                                <Text className="product-specification-text">
                                  Power
                                </Text>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={20} xs={24} sm={24} md={20} lg={16} xl={15} className="book-demo-form-content">
                  <Divider style={{ margin: '0 0 10px 0' }} />
                  <Row gutter={16}>
                    <Col span={24} sm={24} xs={24} md={12}>
                      <Text className="label-style">First Name</Text>
                      <Form.Item
                        rules={[
                          { required: true, message: "Please enter first name!" },
                          {
                            pattern: "^[a-zA-Z ]+$",
                            message: "please enter only alphabets",
                          }
                        ]}
                        name="firstName"
                      >
                        <Input
                          placeholder="Enter First name"
                          style={{ height: "56px", marginTop: 5, color: "#000", textTransform: 'capitalize' }}
                        // className="PU-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={24} xs={24} md={12}>
                      <Text className="label-style">Last Name</Text>
                      <Form.Item
                        rules={[
                          { required: true, message: "Please enter last name!" },
                          {
                            pattern: "^[a-zA-Z ]+$",
                            message: "please enter only alphabets",
                          }
                        ]}
                        name="lastName"
                      >
                        <Input
                          placeholder="Enter Last name"
                          style={{ height: "56px", marginTop: 5, color: "#000", textTransform: "capitalize" }}
                        // className="PU-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={24} xs={24} md={24}>
                      <Text className="label-style">Email</Text>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please enter email address!",
                          },
                          {
                            pattern: "^([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$",
                            message: "please enter correct email",
                          }
                        ]}
                        name="email"
                      >
                        <Input
                          placeholder="Enter Email"
                          style={{ height: "56px", marginTop: 5, color: "#000" }}
                        // className="PU-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={24} xs={24} md={24}>
                      <Text className="label-style">Phone number</Text>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please enter phone number!",
                          },
                          {
                            pattern: "^([0-9]{12})$",
                            message: "please enter correct phone number",
                          }
                        ]}
                        name="phoneNumber"
                      >
                        <Input
                          placeholder="Please add 91 as prefix for mobile number"
                          style={{ height: "56px", marginTop: 5, color: "#000" }}
                        // className="PU-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={24} xs={24} md={24}>
                      {demoType === "HOME_DEMO_ONLY" ? (
                        <>
                          {/* handle for demoType === HOME_DEMO_ONLY */}
                          <Text className="label-style">Choose demo type</Text>
                          <div>
                            <Radio.Group
                              onChange={onChange}
                              value="HOME_DEMO_ONLY"
                              defaultValue="HOME_DEMO_ONLY"
                              style={{ marginTop: "10px", marginBottom: "15px" }}
                            >
                              <Radio disabled={false} value="HOME_DEMO_ONLY">
                                Home demo
                              </Radio>
                              <Radio disabled={true} value="DEALER_DEMO_ONLY">
                                Dealer demo
                              </Radio>
                            </Radio.Group>
                          </div>
                          <div>
                            <Text className="label-style">Address</Text>
                            <Form.Item
                              rules={[
                                {
                                  required:
                                    dealerData?.demoType === "HOME_DEMO_ONLY"
                                      ? true
                                      : false,
                                  message: "Please enter your address",
                                },
                              ]}
                              name="userAddress"
                            >
                              <Input
                                placeholder="Enter your address"
                                style={{ height: "56px", color: "#000" }}
                              // className="PU-input"
                              />
                            </Form.Item>
                          </div>
                        </>
                      ) : demoType === "DEALER_DEMO_ONLY" ? (
                        <>
                          {/* handle for demoType === DEALER_DEMO_ONLY */}
                          <Text className="label-style">Choose demo type</Text>
                          <div>
                            <Radio.Group
                              value="DEALER_DEMO_ONLY"
                              defaultValue="DEALER_DEMO_ONLY"
                              style={{ marginTop: "10px", marginBottom: "15px" }}
                            >
                              <Radio disabled={true} value="HOME_DEMO_ONLY">
                                Home demo
                              </Radio>
                              <Radio disabled={false} value="DEALER_DEMO_ONLY">
                                Dealer demo
                              </Radio>
                            </Radio.Group>
                          </div>
                          <div>
                            <Text className="label-style">
                              Choose Dealer's Location
                            </Text>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please select dealer's location!",
                                },
                              ]}
                              name="dealerLocation"
                            >
                              <Select
                                placeholder="Please select a dealer's location"
                                options={dealerLocation}
                                // className="PU-input"
                                style={{ height: "56px", color: "#000" }}
                              ></Select>
                            </Form.Item>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* handle for demoType === BOTH */}
                          <Text className="label-style">Choose demo type</Text>
                          <div>
                            <Radio.Group
                              onChange={onChange}
                              value={value}
                              style={{ marginTop: "10px", marginBottom: "15px" }}
                            >
                              <Radio value="HOME_DEMO_ONLY" style={{ color: '#8c8c8c' }}>Home demo</Radio>
                              <Radio value="DEALER_DEMO_ONLY" style={{ color: '#8c8c8c' }}>Dealer demo</Radio>
                            </Radio.Group>
                          </div>
                          <div>
                            {value === "HOME_DEMO_ONLY" ? (
                              <>
                                <Text className="label-style">Address</Text>
                                <Form.Item
                                  rules={[
                                    {
                                      required:
                                        dealerData?.demoType === "HOME_DEMO_ONLY"
                                          ? true
                                          : false,
                                      message: "Please enter your address",
                                    },
                                  ]}
                                  name="userAddress"
                                >
                                  <Input
                                    placeholder="Enter your address"
                                    style={{ height: "56px", color: "#000" }}
                                  // className="PU-input"
                                  />
                                </Form.Item>
                              </>
                            ) : (
                              value === "DEALER_DEMO_ONLY" && (
                                <>
                                  <>
                                    <Text className="label-style" >
                                      Choose Dealer's Location
                                    </Text>
                                    <Form.Item
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please select dealer's location!",
                                        },
                                      ]}
                                      name="dealerLocation"
                                    >
                                      <Select
                                        placeholder="Please select a dealer's location"
                                        options={dealerLocation}
                                        // className="PU-input"
                                        style={{ height: "56px", color: "#000" }}
                                      ></Select>
                                    </Form.Item>
                                  </>
                                </>
                              )
                            )}
                          </div>
                        </>
                      )}
                    </Col>
                    <Col span={24} sm={24} xs={24} md={24}>
                      <Flex gap="large" wrap="wrap">
                        <Form.Item
                          name="addressProof"
                        // label="Address proof"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please upload required documents",
                        //   },
                        // ]}
                        >
                          <Text className="label-style">Address proof (Optional)</Text>
                          <Flex gap="large" wrap="wrap" style={{ marginTop: 5 }}>
                            <Upload {...addressInfoProp} maxCount={1}>
                              <Button icon={<UploadOutlined />} style={{ color: '#8c8c8c' }}>
                                Click to Upload
                              </Button>
                            </Upload>
                          </Flex>
                          {addressInfoError !== "" && <Text type="danger">{addressInfoError}</Text>}
                        </Form.Item>
                        <Form.Item
                          name="IDproof"
                        // label="ID proof"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please upload required documents",
                        //   },
                        // ]}
                        >
                          <Text className="label-style">ID proof (Optional)</Text>
                          <Flex gap="large" wrap="wrap" style={{ marginTop: 5 }}>
                            <Upload {...idInfoProp} maxCount={1}>
                              <Button icon={<UploadOutlined />} style={{ color: '#8c8c8c' }}>
                                Click to Upload
                              </Button>
                            </Upload>
                          </Flex>
                          {idInfoError !== "" && <Text type="danger">{idInfoError}</Text>}
                        </Form.Item>
                      </Flex>
                    </Col>
                    <Col span={24} style={{ marginTop: 10 }}>
                      <Row>
                        <Col span={12} xs={10} sm={9} md={6} lg={5} xl={4} xxl={4}>
                          <Button
                            // className="save-btn"
                            style={submitButton}
                            loading={isLoading}
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                        <Col span={12} xs={10} sm={9} md={6} lg={5} xl={4} xxl={4} style={{ marginLeft: '10px' }}>
                          {/* <Link to={{ pathname: `/product/${productId}` }}> */}
                          <Button style={closeButton} onClick={handleReset}>Cancel</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
      {/* <BookingPlacedModal isModalOpen={isModalOpen} onModalClose={onModalClose} /> */}
    </>
  );
};
export default BookYourDemo;
