import { Col, Row, Typography, Image } from 'antd'
import "./poweringUpStyle/secondSection.css"
const { Text, Paragraph, Title } = Typography


const SecondSection = ({step, index}) => {

  return (
    <div key={index} >
      <Row className={step.addCover && 'overlay'} >
        <Col lg={{span:24}} xs={{span: 24}}  className='step-top-container'>
          <Col md={{span: 5 }} xs={{span: 24}} className='step-first' >
            <Text className='step-title' >{step.step}</Text>
            <Col className='battery-image-section' >
              <Image src={step.batteryImage} preview={false} />
            </Col>
          </Col>
          <Col md={{span: 11 }} xs={{span: 24}}  className="step-center" >
            <Text className='step-sub-title'>{step.title}</Text>
            <Paragraph className='step-description' >{step.content}</Paragraph>
          </Col>
          <Col md={{span: 8 }} xs={{span: 24}}  >
            <Image src={step.rightImage} preview={false}/>
          </Col>
        </Col>
      </Row>
    </div>
  )
}

export default SecondSection