import { useEffect, useState } from "react";
import { Col, Typography, Image, Card, Button, Rate, message, Flex, Divider, Row, Tooltip } from "antd";
import { HeartOutlined } from "@ant-design/icons";
import vector5 from "../../assets/images/Vector5.svg";
import "./style/product.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCartItem } from "../../Redux/actionTypes";
import { addProductToCart, getUserCartList, userLogin } from "../../api/user";
import { initOTPless } from "../../utils/initOtpless";
import { formatToRupees } from "../../utils/formatToRupees";
import ReactGA from 'react-ga4';
import locationNotFound from "../../assets/location-svg1.svg"
import locationFound from "../../assets/location-found.svg"
import { IoLocation } from "react-icons/io5";

const { Text } = Typography;

const ProductCard = ({ product }) => {
  const [userData, setUserData] = useState(
    localStorage.getItem("otpless_user_token")
  );
  const [isWishlistClicked, setIsWishlistClicked] = useState(false);
  const [averageRating, setAverageRating] = useState(0)
  const authState = useSelector((state) => (state ? state.auth : []));

  // const isUnavailable =
  //   authState?.userCoords !== undefined && authState?.userPincode !== undefined
  //     // ? !product?.availableLocations?.includes(Object.values(authState.userPincode))
  //     ? !(product?.availableLocations?.some(r => Object.values(authState.userPincode).includes(r)) || (product?.availableLocations.includes("ALL_LOCATIONS")))
  //     : true;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeModal = (e) => {
    const modalContainer = document.getElementById("modalContainer");
    modalContainer.style.display = "none";
  };

  const callback = async (otplessUser) => {
    const tokenObj = {
      token: otplessUser.token,
      email: otplessUser.email?.email,
      waNumber: otplessUser.waNumber,
      waName: otplessUser.email?.name !== null ? otplessUser.email?.name : "",
    };
    const userData = await userLogin(tokenObj);
    const { auth_token, ...userInfo } = userData.data.data;
    // Save token and user info in localStorage
    localStorage.setItem("auth_token", auth_token);
    localStorage.setItem("user_details", JSON.stringify(userInfo));
    localStorage.setItem("otpless_user_token", JSON.stringify(tokenObj));
    setUserData(otplessUser);
    getUserCartList().then((response) => {
      dispatch(updateCartItem(response));
    });
    closeModal();
  };

  const total_rating_count = () => {
    if (product?.reviews?.length > 0) {
      const rating_count = product.reviews.map((e) => e.rating)
      const total_rating = rating_count.reduce((a, b) => a + b, 0);
      const average_rating = (total_rating / rating_count.length) || 0;
      setAverageRating(average_rating)
    }
  }

  const openModal = () => {
    if (userData) {
      return;
    }
    initOTPless(callback);
    const modalContainer = document.getElementById("modalContainer");
    if (modalContainer) {
      modalContainer.style.display = "flex";
    }
    // modalContainer !== null ? (modalContainer.style.display = "flex") : "";
  };

  const handleAddToCart = (itemDetails) => {
    navigate(`/book-a-demo?productId=${itemDetails?.id}&vendorId=${itemDetails?.vendorId}&pincode=${authState.userPincode?.pincode}`)
  };

  useEffect(() => {
    total_rating_count()
  }, [])

  const wishlistIconColor = isWishlistClicked ? "red" : "gray";
  const productImage = product?.productImages?.split(",")?.[0];
  const handleWishlistClick = () => {
    setIsWishlistClicked(!isWishlistClicked);
  };

  const handleClick = (product) => {
    window.dataLayer.push({
      event: 'product',
      eventProps: {
        category: "Product Details",
        action: "Click",
        label: "Product",
        value: product?.name
      }
    });
    window.fbq('track', 'ViewContent', {
      content_name: product?.name,
      content_category: product?.brandName,
      content_ids: [product.id],
      content_type: product?.category,
      value: product?.productPrice,
      currency: 'INR'
    });
    ReactGA.send({
      hitType: "product",
      page: window.location.pathname,
    });
    ReactGA.event({
      category: 'Social Links',
      action: 'Click',
      label: product?.name,
    });
  };


  return (
    <Link to={`/product/${product.id}`} onClick={() => handleClick(product)}>
      <div
        className="product-card"
      >
        <Col span={24} className="product-card-img-container">
          <div className="discount-percent-display">
            <Text className="discount-percent-text">{product.discount}%{'\n'} off</Text>
          </div>
          <img
            className="product-card-img"
            alt={product.productName || product.name}
            src={productImage || vector5}
            preview={false}

          />
        </Col>
        <Col span={24} className="product-details-container">
          <div style={{ padding: '0 5px', }}>
            <div style={{ borderBottom: '1px solid #d9d9d9', padding: '0px 20px', position: 'relative' }}>
              {/* <div style={{ display: 'flex', justifyContent: "end", alignItems: 'center', position: "absolute", right: "0", top: "-10px", gap: "5px" }}>
                <Text style={{ whiteSpace: "wrap", fontSize: "14px", color: isUnavailable ? "#ff7c7c" : "#00FFFF" }}>{isUnavailable ? "Not Available" : "Available"}</Text>
                {
                  isUnavailable ?


                    <Tooltip placement="bottom" title={"Unable to Demo at your location"} style={{ fontFamily: "AvenirNextLTPro" }}>  <IoLocation style={{ color: "#ff7c7c", fontSize: "1rem" }}> </IoLocation></Tooltip>


                    : <IoLocation style={{ color: "#00ffff", fontSize: "1rem" }}> </IoLocation>
                }
              </div> */}
              <div style={{ display: "flex", width: '100%', alignItems: "center" }}>
                <Text className="product-card-title" style={{ display: "flex", justifyContentL: "space-between", alignItems: "center", }}>
                  <span>{product.productName || product.name}</span>
                </Text>

              </div>

              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between', width: "100%", alignItems: 'center', margin: "10px 0px", }}>
                <Rate allowHalf disabled value={product.ratings} style={{ color: '#FF8F28', margin: '0px 0 0px 0', fontSize: '16px' }} />
                <Text>  <span style={{ fontSize: "15px", whiteSpace: "nowrap", color: "#00ffff", fontWeight: "600" }}>{product.brandName}</span></Text>
              </div>

              <Flex gap="large" justify="space-between">
                <Text className="product-card-discounted-price">
                  ₹{formatToRupees((product.sellingPrice + ((product.sellingPrice * 5) / 100)).toFixed(0))}
                  <sub style={{ fontSize: '10px' }}>#incl. GST</sub>
                </Text>
                <Text className="product-card-original-price" delete>
                  ₹{formatToRupees(product.productPrice)}
                </Text>
              </Flex>
            </div>
          </div>
          <Row style={{ paddingTop: '10px' }}>
            <Col span={8} style={{ textAlign: 'center', padding: '5px' }}>
              <Text className="product-specification-value">
                {product.range_Overview} km
              </Text>
              <div>
                <Text className="product-specification-text">
                  Range
                </Text>
              </div>
            </Col>
            <Col span={8} style={{ textAlign: 'center', padding: '5px' }}>
              <Text className="product-specification-value">
                {product.topSpeed_Overview} km/h
              </Text>
              <div>
                <Text className="product-specification-text">
                  Top Speed
                </Text>
              </div>
            </Col>
            <Col span={8} style={{ textAlign: 'center', padding: '5px' }}>
              <Text className="product-specification-value">
                {product.chargingTime} hours
              </Text>
              <div>
                <Text className="product-specification-text">
                  Charging time
                </Text>
              </div>
            </Col>
          </Row>
        </Col>
      </div >
    </Link >
  );
};

export default ProductCard;
