import React from 'react'
import { Row, Col, Image, Typography, Button } from 'antd'
import vector3 from "../../assets/images/Vector3.svg"
import "./homeStyle/secondSection.css"
import { Link } from 'react-router-dom'

const { Text } = Typography

const SecondSection = () => {
    return (
        <Row className='second-main-container'>
            <Col xs={24} md={14} lg={12} className='second-left-container'>
                <Col className='second-left-sub-container'>
                    <Text className='second-title'>Why should you own an EV in 2024? </Text>
                    <Text className='second-sub-title'>EVs are highly efficient </Text>
                    {/* <Text className='second-description'>EVs convert 77% of the energy from the grid into movement as compared to 20% by petrol cars, ________ —  making for promising travel companions </Text> */}
                    <Text className='second-sub-title'>They don’t burn a hole in your pocket </Text>
                    {/* <Text className='second-description'>Unlike other fuel ”burning” vehicles, EVs are healthier for the planet & your wallet in the long run</Text> */}
                    <Text className='second-sub-title'>A smarter choice for the planet </Text>
                    {/* <Text className='second-description'>EVs have zero tailpipe emissions & leave way lesser carbon footprint on the environment, making it to be a sustainable & smarter choice.</Text> */}

                    {/* <Text className='second-description'>At Lilypad, we're not just here to sell electric vehicles – we're here to revolutionize the way you move. We are passionate advocates for electric mobility, and our primary goal is to enable you to make the right choice.</Text> */}
                    <Link to={'/products'}>
                        <Button className='second-explore-btn-gred'><b>Explore our range</b></Button>
                    </Link>
                </Col>
            </Col>

            <Col xs={24} md={10} lg={12} className='second-right-container'>
                {/* <Text className='second-style'>LILYPAD</Text> */}
            </Col>

        </Row>
    )
}

export default SecondSection