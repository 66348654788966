import FirstSection from '../Components/Home/FirstSection'
import SecondSection from '../Components/Home/SecondSection'
import ThirdSection from '../Components/Home/ThirdSection'
import ClientTestimonials from '../Components/ClientTestimonials'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBrands, getAllCategories, getAllProduct, getFiltered } from '../api/product'
import { useEffect, useState } from 'react'
import { getUserPincode } from '../utils/googleMaps'
import { setUserPincode } from '../Redux/actionTypes/authAction'
import { setBrandsData, setCategoriesData, setProductColors, setProductData } from '../Redux/actionTypes/productAction'
import Promotion from '../Components/Home/Promotion'
// import { CollectionUIComponent } from "@decentro1/collections-payment-sdk-web";



const Home = () => {
  const authState = useSelector((state) => (state ? state.auth : []));
  const productState = useSelector((state) => (state ? state.product : []));
  const dispatch = useDispatch();
  const [products, setProducts] = useState(productState?.products?.length > 0 ? productState?.products : [])
  const [isLoading, setIsLoading] = useState(false)

  // const urlHeader = {
  //   client_id: "lilypad_prod", //<your_client_id>
  //   client_secret: "JBIvHEX3xKMnUJrGpTL0rLSLlIYVrzJu", // <your_client_secret>
  //   module_secret: "OyzFeOeoPgc7rCyBq5t31QmJ4fLdBsA3", // <your_module_secret>
  //   provider_secret: "ZerzHGaKMdcnrzPkdx2jmmlzTDG7DcTa", // <YOUR_PROVIDER_SECRET>
  // };

  // // API body
  // const urlBody = {
  //   reference_id: "432-433-534-998-999-111", //unique transaction id
  //   payee_account: "D86063459293111512", //payee account number
  //   amount: 10, // amount to be deducted
  //   purpose_message: "String", // payment message
  //   generate_qr: 1, // 0 = do not generate QR , 1 = to generate QR
  //   expiry_time: 10, //expiry time for payment
  //   customized_qr_with_logo: 0, //logo based QR then pass 1
  //   generate_uri: 1, // to generate the deep links 
  // };

  // const [linkAPIResponse, setlinkAPIResponse] = useState(null);
  // const [paymentAPIResponse, setPaymentAPIResponse] = useState(null);
  // const [upiValidateAPIResponse, setUpiValidateAPIResponse] = useState(null);
  // const [collectionAPIResponse, setCollectionAPIResponse] = useState(null);

  const getProductFromApi = async (offset) => {
    setIsLoading(true)
    try {
      // const prod = await getAllProduct(offset);
      const prod = await getFiltered(offset, { searchTerm: "" });
      if (prod) {
        let publishedProducts = prod?.data.filter((item) => item.isPublished);
        setProducts(publishedProducts.slice(0, 6))
        dispatch(setProductData(publishedProducts));
        dispatch(setProductColors(prod?.colors))
        setIsLoading(false)
      }
    } catch (error) {
      // console.error("Error fetching products:", error);
      setIsLoading(false)
    }
  };

  const getPincode = async () => {
    if (authState.userCoords) {
      setIsLoading(true)
      const pincode = await getUserPincode(authState.userCoords);
      setIsLoading(false)
      dispatch(setUserPincode(pincode));
    }
  };

  const getBrands = async () => {
    try {
      const brands = await getAllBrands();
      if (brands) {
        const uniqueBrands = [...new Set(brands.map(obj => JSON.stringify(obj)))].map(str => JSON.parse(str));
        dispatch(setBrandsData(uniqueBrands))
      }
    } catch (error) {
      // console.error("Failed to fetch brand filter data!");
    }
  };

  const getCategories = async () => {
    try {
      const categories = await getAllCategories()
      if (categories) {
        categories.push({ name: "All" })
        const allObject = categories.find(item => item.name === "All");
        const remainingData = categories.filter(item => item !== allObject);

        const sortedRemainingData = remainingData.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        let final_categories = [allObject, ...sortedRemainingData]
        final_categories = final_categories.filter((item) => item.isPublished);
        dispatch(setCategoriesData(final_categories));
      }
    } catch (error) {
      // console.error("Failed to fetch categories!");      
    }
  }

  useEffect(() => {
    if (authState?.userPincode === undefined) {
      getPincode()
    }
  }, [authState.userCoords])

  useEffect(() => {
    if (productState?.products?.length === 0) {
      getProductFromApi({
        city: authState.userPincode?.city,
        district: authState.userPincode?.district,
        state: authState.userPincode?.state,
      });
      setIsLoading(false)
    }

    if (productState?.brands?.length === 0) {
      getBrands();
    }

    if (productState?.categories?.length === 0) {
      getCategories();
    }
  }, [authState.userPincode]);

  // useEffect(() => {
  //   console.log(paymentAPIResponse);
  // }, [paymentAPIResponse])

  return (
    <>
      <FirstSection />
      {/* <CollectionUIComponent
        urlHeaderData={urlHeader}
        urlBodyData={urlBody}
        linkGenerationAPIRes={setlinkAPIResponse} //will return api response for generate payment link
        gtsStatusAPIRes={setPaymentAPIResponse} //will return api response for check payment status
        upiValidateAPIRes={setUpiValidateAPIResponse} //will return api response for validate UPI ID
        collectionReqAPIRes={setCollectionAPIResponse} //will return api response for issue collection request
      /> */}
      <Promotion />
      <SecondSection />
      <ThirdSection products={products} isLoading={isLoading} locationEnabled={authState?.locationEnabled} />
      <ClientTestimonials />
    </>
  )
}

export default Home