import React from 'react'
import FirstSection from '../Components/PoweringUp/FirstSection'
import SecondSection from '../Components/PoweringUp/SecondSection'

const PoweringUp = () => {

  const steps = [
    {
      step: 'Step 1',
      batteryImage: '/images/battery1.png',
      title: 'Build & Launch India’s Go-To EV Marketplace ',
      content: 'The first milestone in our journey — to build & launch a marketplace where EVs & accessible to every person who wants to make the transition.With this platform, we aim to bridge the gap between accessibility & knowledge in the EV market.',
      addCover: false,
      rightImage: './images/step-1.webp',
    }, {
      step: 'Step 2',
      batteryImage: '/images/battery2.png',
      title: 'Bridging the Talent Gap',
      content: "In our quest, we've encountered a significant talent gap in the electric vehicle industry. The scarcity of trained resources is a barrier to progress. To address this, we aim to partner with the finest training companies to offer the best EV training courses, empowering individuals to develop the skills required to shape a cleaner future.",
      addCover: true,
      rightImage: './images/step-2.webp',
    }, {
      step: 'Step 3',
      batteryImage: '/images/battery3.png',
      title: 'Connecting Companies and Talent',
      content: "We envision becoming the bridge that connects forward-thinking companies with individuals equipped with EV expertise. As matchmakers, we'll facilitate the synergy that propels the EV industry to new heights, bringing employment opportunities to the forefront.",
      addCover: true,
      rightImage: './images/step-3.webp',
    }, {
      step: 'Step 4',
      batteryImage: '/images/battery4.png',
      title: 'Embracing the Gig Economy',
      content: "We're fervent advocates of the gig economy's flexibility and innovation. In Step 4, we aim to create a gig marketplace tailored to EV workers. This platform will enable individuals to offer their skills and services within the electric vehicle sector while providing companies with on-demand expertise.",
      addCover: true,
      rightImage: './images/step-4.webp',
    }, {
      step: 'Step 5',
      batteryImage: '/images/battery5.png',
      title: 'Becoming a Charge Point Operator (CPO)',
      content: "To complete our vision of accelerating a sustainable energy future, we intend to establish a comprehensive network of solar powered charging stations in Delhi NCR to start with, but quickly moving pan India.",
      addCover: true,
      rightImage: './images/step-1.webp',
    }
  ]

  return (
    <div>
      {/* title  */}
      <FirstSection />
      {steps.map((step, index) => <SecondSection step={step} index={index} />)}
    </div>
  )
}

export default PoweringUp