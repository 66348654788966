import { Row, Col, Image } from 'antd'

//@ts-ignore
import "./visionStyle/fifthSection.css"
//@ts-ignore
import vector44 from "../../assets/images/blue scooty front 7.png"
//@ts-ignore
import vector34 from "../../assets/images/Free Floating Business Card Mockup 2.png"
//@ts-ignore
import vector35 from "../../assets/images/Free Woman Holding Bottle Mockup.png"
//@ts-ignore
import vector36 from "../../assets/images/macbook-16-pro-mockup-on-concrete-background-front-view.png"
//@ts-ignore
import vector37 from "../../assets/images/Metallic Package.png"
//@ts-ignore
import vector39 from "../../assets/images/t shirt back mockup.png"


const SixthSection = () => {
    return (
        <div className='vision-fifth-section-container'>
            <Row>
                <Col xs={24} md={12} lg={11} className=''>
                    <Image src={vector34} preview={false} className='vison-images'/>
                    <Image src={vector35} preview={false} className='vison-images'/>
                </Col>
                <Col xs={0} md={12} lg={12} className='vision-fifth-col-2'>
                    <Image src={vector36} preview={false} width={'525px'}/>
                </Col>
                <Col span={24} style={{padding:'20px'}}>
                    <Row>
                        <Col span={8}>
                            <Image src={vector37} preview={false} className='bottom-imgs' />
                        </Col>
                        <Col span={8}>
                            <Image src={vector44} preview={false} className='bottom-imgs' />
                        </Col>
                        <Col span={8}>
                            <Image src={vector39} preview={false} className='bottom-imgs' />
                        </Col>
                    </Row>
                </Col>
            </Row> 
        </div>
    )
}

export default SixthSection