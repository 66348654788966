import {
  Row,
  Col,
  Typography,
  Image,
  Input,
  Form,
  message,
  Button,
  Select,
  Upload,
  Modal,
  Radio,
  Space,
  Flex,
  Checkbox,
} from "antd";
import "../../src/Components/ProductUpload/styles/style.css";
import logo from "./../assets/Combination Mark PNG-02.png";
import { createProduct } from "../api/product";
import { useEffect, useRef, useState } from "react";
import { getAllCategory } from "../api/category";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { useSearchParams } from "react-router-dom";
import authApiService from "../api/authApiService";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
const ProductUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [passwordModal, setPasswordModal] = useState(true);
  const [password, setPassword] = useState();
  const [vendorData, setVendorData] = useState();
  const [bmsCert, setBmsCert] = useState(false);
  const [subsidy, setSubsidy] = useState(false)
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const [uploadedDocFileList, setUploadedDocFileList] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [uploadedFileList, setUploadedFileList] = useState([]);
  const [docError, setDocError] = useState({ bmsError: "", subsidyError: "" });
  const vendorId = searchParams.get("id");
  const { TextArea } = Input;
  const { Title, Text } = Typography;
  // let productImages = [];

  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  };

  const getVendorInfo = async () => {
    try {
      const { data } = await authApiService.get(`/auth/vendor/${vendorId}`);
      if (data) {
        setVendorData(data.data);
        form.setFieldValue("brandName", data.data?.brandName);
      }
    } catch (error) {
      message.error("Failed to fetch vendor info! please try again.");
    }
  };

  const onProductImageRemove = (file) => {
    setUploadedFileList((prevList) => {
      const newUrls = prevList.filter((item) => item.uid !== file.uid);
      return newUrls;
    });

    setProductImages((prevList) => {
      const newUrls = prevList.filter((item) => item.uid !== file.uid);
      message.success(`${file.name} removed from the list`);
      return newUrls;
    });
  }

  const onDocRemove = (file) => {
    setUploadedDocFileList((prevList) => {
      const newUrls = prevList.filter((item) => item.uid !== file.uid);
      message.success(`${file.name} removed from the list`);
      return newUrls;
    });
  }

  const customRequest = async ({ file, onSuccess, onError, propName }) => {
    if (!file) {
      // console.error("Please select a file.");
      return;
    }

    const bucketName = process.env.REACT_APP_AWS_S3_PRODUCT_IMAGE_BUCKET;
    const bucketRegion = process.env.REACT_APP_AWS_S3_BUCKET_REGION;
    const awsAccessKey = process.env.REACT_APP_AWS_LILYPAD_PRODUCTS_ACCESS_KEY;
    const awsSecretKey = process.env.REACT_APP_AWS_LILYPAD_PRODUCTS_SECRET_KEY;
    const s3Client = new S3Client({
      region: bucketRegion,
      credentials: {
        accessKeyId: awsAccessKey,
        secretAccessKey: awsSecretKey,
      },
    });

    // Set the S3 parameters
    const params = {
      Bucket: bucketName,
      Key: file.name,
      Body: file,
      ContentType: file.type,
    };

    try {
      const command = new PutObjectCommand(params);
      const response = await s3Client.send(command);

      onSuccess({ url: response.Location });
      message.success("File uploaded successfully.");
      let bucketBaseUrl = process.env.REACT_APP_AWS_BUCKET_BASE_URL;
      let fileUrl = `${bucketBaseUrl}/${encodeURIComponent(file.name)}`;
      if (propName === 'productMedia') {
        setProductImages((prevList) => [...prevList, { uid: file.uid, url: fileUrl }]);
        // productImages.push(fileUrl);
      }
      else if (propName === 'subsidyProps') {
        const docObj = {
          uid: file.uid,
          url: fileUrl,
          source: propName
        }
        setUploadedDocFileList([...uploadedDocFileList, docObj])
        const errors = { ...docError };
        errors.subsidyError = "";
        setDocError(errors)
      }
      else if (propName === 'bmsProps') {
        const docObj = {
          uid: file.uid,
          url: fileUrl,
          source: propName
        }
        setUploadedDocFileList([...uploadedDocFileList, docObj])
        const errors = { ...docError };
        errors.bmsError = "";
        setDocError(errors)
      }
      return fileUrl;
    } catch (error) {
      // console.error("Error uploading file to S3:", error);
      onError(error);
      message.error("File upload failed.");
    }
  };

  const props = {
    beforeUpload: (file) => {
      const allowedFileTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
        "video/mp4",
        "video/webm",
      ];
      const isAllowedFileType = allowedFileTypes.includes(file.type);

      if (!isAllowedFileType) {
        message.error("You can only upload image or video.");
        return Upload.LIST_IGNORE;
      }

      if (file.type.startsWith("video/")) {
        if (uploadedFileList.some((f) => f.type.startsWith("video/"))) {
          message.error("You can only upload one video.");
          return Upload.LIST_IGNORE;
        }
      }

      return true;
    },

    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      // e.preventDefault();
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        // const updatedFileList = [...uploadedFileList];
        // updatedFileList.push(info.file);
        // uploadedFileList = updatedFileList;
        // console.log(`${info.file.name} file uploaded successfully`);
        setUploadedFileList((prevList) => [...prevList, info.file]);
      } else if (info.file.status === "error") {
        // console.log(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: (options) => customRequest({ ...options, propName: 'productMedia' }),
  };

  const bmsProps = {
    beforeUpload: (file) => {
      const allowedFileTypes = [
        "application/pdf"
      ];
      const isAllowedFileType = allowedFileTypes.includes(file.type);

      if (!isAllowedFileType) {
        message.error("Please upload file in pdf format.");
        return Upload.LIST_IGNORE;
      }

      return true;
    },

    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        // console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // console.log(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: (options) => customRequest({ ...options, propName: 'bmsProps' }),
  };

  const subsidyProps = {
    beforeUpload: (file) => {
      const allowedFileTypes = [
        "application/pdf"
      ];
      const isAllowedFileType = allowedFileTypes.includes(file.type);

      if (!isAllowedFileType) {
        message.error("Please upload file in pdf format.");
        return Upload.LIST_IGNORE;
      }

      return true;
    },

    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        // console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // console.log(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: (options) => customRequest({ ...options, propName: 'subsidyProps' }),
  };

  function parseNumericalFields(obj) {
    const numericalFields = [
      "itemWeight",
      "size",
      "sellingPrice",
      "productPrice",
      "discount",
      "productQuantity",
    ];

    for (const field of numericalFields) {
      if (obj[field]) {
        obj[field] = parseFloat(obj[field]);
      }
    }

    return obj;
  }

  const PositiveNumberValidator = (rule, value) => {
    const number = parseInt(value);
    if (isNaN(number) || number <= 0) {
      return Promise.reject("Price can't be negative!");
    } else {
      return Promise.resolve();
    }
  };

  const formatCategoryOptions = (categories) => {
    const formattedOptions = categories.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    setCategoryOptions(formattedOptions);
  };

  const getCategories = async () => {
    try {
      const { data } = await getAllCategory();
      if (data) {
        let publishedCategories = data?.data.filter((item) => item.isPublished);
        formatCategoryOptions(publishedCategories);
      }
    } catch (error) {
      message.error("Failed to fetch categories!");
    }
  };

  const getCategorieNameById = (categoryId) => {
    const category = categoryOptions.find(
      (category) => category?.value === categoryId
    ) || { label: "" };
    return category?.label;
  };

  const AddProduct = async (body) => {
    setIsLoading(true);
    const bmsFile = uploadedDocFileList.find(object => object.source === 'bmsProps');
    const subsidyFile = uploadedDocFileList.find(object => object.source === 'subsidyProps');
    if (bmsCert && !bmsFile) {
      const errors = { ...docError };
      errors.bmsError = "Please upload BMS document.";
      setDocError(errors)
      setIsLoading(false)
      return;
    }
    if (subsidy && !subsidyFile) {
      const errors = { ...docError };
      errors.subsidyError = "Please upload BMS document.";
      setDocError(errors)
      setIsLoading(false)
      return;
    }

    let parsedFormFields = parseNumericalFields(body);

    if (Array.isArray(body.color)) {
      parsedFormFields.color = body.color.toString()
    }

    parsedFormFields.categoryId = parsedFormFields.category;
    parsedFormFields.category = getCategorieNameById(parsedFormFields.category);
    let urlString = productImages.map(obj => obj.url).join(',');

    parsedFormFields.bms_certification = bmsFile?.url
    parsedFormFields.ProductApprovedForSubsidyUnderEMPS2024 = subsidyFile?.url

    var availableLocations = [];
    vendorData?.dealers.map((e) => {
      availableLocations.push(e["city"]);
      availableLocations.push(e["district"]);
      availableLocations.push(e["state"]);
      if (e["deliveryType"] === "ALL_LOCATIONS" && !availableLocations.includes("ALL_LOCATIONS")) {
        availableLocations.push(e["deliveryType"]);
      }
    });

    // parsedFormFields.pincodes = vendorData?.dealers?.map(
    //   (item) => item.location.pincode
    // );

    parsedFormFields.availableLocations = availableLocations;
    parsedFormFields.productImages = urlString;
    parsedFormFields.searchName = parsedFormFields.name.toLowerCase();
    parsedFormFields.vendorId = vendorId;
    delete parsedFormFields.file;
    try {
      const { data } = await createProduct(parsedFormFields);
      if (data) {
        message.success("Product added successfully");
        setIsLoading(false);
        form.resetFields();
        form.setFieldValue("brandName", vendorData.brandName);
        // productImages = [];
        setProductImages([]);
      }
    } catch (error) {
      message.error("Failed to add product!");
      setIsLoading(false);
    }
  };

  const verifyVendorPassword = async () => {
    setIsLoading(true);
    let values = {
      password,
      id: vendorId,
    };
    try {
      const { data } = await authApiService.post(
        "/auth/vendor/authenticate",
        values
      );
      if (data.error === false) {
        setIsLoading(false);
        window.localStorage.setItem("vendorToken", data?.token);
        setPasswordModal(false);
      } else {
        setIsLoading(false);
        message.error("Incorrect Password! Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      message.error("Failed to verify your password!");
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (vendorId) {
      getVendorInfo(vendorId);
    }
  }, [vendorId]);

  const handleChange = (value) => {
    // console.info(`Selected: ${value}`);
  };

  const colorOptions = [
    { label: 'White', value: 'White' },
    { label: 'Black', value: 'Black' },
    { label: 'Gray', value: 'Gray' },
    { label: 'Silver', value: 'Silver' },
    { label: 'Blue', value: 'Blue' },
    { label: 'Red', value: 'Red' },
    { label: 'Brown', value: 'Brown' },
    { label: 'Green', value: 'Green' },
    { label: 'Orange', value: 'Orange' },
    { label: 'Beige', value: 'Beige' },
    { label: 'Purple', value: 'Purple' },
    { label: 'Gold', value: 'Gold' },
    { label: 'Yellow', value: 'Yellow' },
    { label: 'Violet', value: 'Violet' }
  ]

  return (
    <>
      <Modal
        closeIcon={false}
        style={{ color: "#fff" }}
        centered
        title="Enter Password"
        open={passwordModal}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            className="save-btn"
            onClick={verifyVendorPassword}
          >
            Submit
          </Button>,
        ]}
      >
        <Row>
          <Col>
            <Input
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
      <Row className="first-section" justify="center" align="top">
        <Col
          className="first-section-col"
          span={24}
          style={{ textAlign: "center" }}
        >
          <Image
            preview={false}
            src={logo}
            className="nav-logo"
            style={{ alignContent: "center", maxWidth: "200px", objectFit: "contain" }}
          />
          <Title level={2} className="heading" style={{ textAlign: "center" }}>
            Upload your product
          </Title>
        </Col>
      </Row>
      <Form
        onFinish={AddProduct}
        form={form}
        name="profile_form"
        layout="vertical"
      >
        <Row align={"top"} justify={"center"}>
          <Col span={18} sm={24} style={{ maxWidth: "1150px" }}>
            <Title level={2} style={{ color: "#0fff" }}>Product Main Information</Title>
          </Col>
          <Col span={18}>
            <Row gutter={32}>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text className="label-style">Product Name *</Text>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "Please enter product name!" },
                  ]}
                >
                  <Input
                    placeholder="Enter your product name"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text className="label-style">Product Price *</Text>
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter a price!" },
                    {
                      validator: PositiveNumberValidator,
                    },
                  ]}
                  name="productPrice"
                >
                  <Input
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter your product price"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text className="label-style">Booking Price *</Text>
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter a price! else 0" },
                    {
                      validator: PositiveNumberValidator,
                    },
                  ]}
                  name="bookingPrice"
                >
                  <Input
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter your booking price else 0"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginTop: "20px" }}
                  name="priceIncludesDelivery"
                  valuePropName="checked"
                >
                  <Checkbox>Price including with delivery?</Checkbox>
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text className="label-style">Product Description * </Text>
                <Form.Item
                  name="productDescription"
                  rules={[
                    {
                      required: true,
                      message: "Please enter product description!",
                    },
                  ]}
                >
                  <TextArea
                    rows={6}
                    placeholder="write your product description here"
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                {/* <Form.Item
                  style={{ marginTop: "20px" }}
                  name="priceIncludesDelivery"
                  valuePropName="checked"
                >
                  <Checkbox>Price including with delivery?</Checkbox>
                </Form.Item> */}
                <Text className="label-style">Product Category *</Text>
                <Form.Item
                  name="category"
                  rules={[
                    { required: true, message: "Please select the category!" },
                  ]}
                >
                  <Select
                    placeholder="Please select a category"
                    options={categoryOptions}
                    className="PU-input"
                    style={{ height: "56px", color: "#000" }}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Form.Item
                  name="file"
                  label="Product Images or Video"
                  rules={[
                    {
                      required: false,
                      message: "Please upload product image or video",
                    },
                  ]}
                >
                  <Flex gap="large" wrap="wrap">
                    <Upload onRemove={onProductImageRemove} {...props}>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                    <Form.Item name="isDemoable" valuePropName="checked">
                      <Checkbox>Demo avilable</Checkbox>
                    </Form.Item>
                    <Form.Item name="isDeliverable" valuePropName="checked">
                      <Checkbox>Delivery available</Checkbox>
                    </Form.Item>
                  </Flex>
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text className="label-style">Brand Name *</Text>
                <Form.Item
                  rules={[{ required: true, message: "Please enter brand!" }]}
                  name="brandName"
                >
                  <Input
                    disabled
                    type="text"
                    placeholder="Enter your brand"
                    style={{ height: "56px", backgroundColor: "#fff" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text className="label-style">Color *</Text>
                <Form.Item
                  name="color"
                  rules={[{ required: true, message: "Please enter a color!" }]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Please select colors"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    // defaultValue={['a10', 'c12']}
                    onChange={handleChange}
                    options={colorOptions}
                  />
                  {/* <Input
                    type="text"
                    placeholder="Enter a color"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  /> */}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={18} sm={24} style={{ maxWidth: "1150px" }}>
            <Title level={2} style={{ color: "#00ffff" }}>Product Variants</Title>
          </Col>
          <Col span={18}>
            <Form.List
              initialValue={[
                {
                  variantType: "",
                  price: null,
                  variant: "",
                  spec: ""
                },
              ]}
              name="variants"
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 8,
                      }}
                      align="baseline"
                    >
                      <Row gutter={32}>
                        <Col span={24} sm={24} xs={24} md={8}>
                          <Form.Item
                            {...restField}
                            style={{ margin: "0" }}
                            name={[name, "variantType"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Variant Type",
                              },
                            ]}
                          >
                            <Input style={{ height: "56px", color: "#000" }}
                              className="PU-input" placeholder="Variant Type" />
                          </Form.Item>
                        </Col>
                        <Col span={24} sm={24} xs={24} md={8}>
                          <Form.Item
                            {...restField}
                            style={{ margin: "0" }}
                            name={[name, "variant"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing variant",
                              }
                            ]}
                          >
                            <Input
                              style={{ height: "56px", color: "#000" }}
                              className="PU-input"
                              placeholder="Variant"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24} sm={24} xs={24} md={8}>
                          <Form.Item
                            {...restField}
                            style={{ margin: "0" }}
                            name={[name, "spec"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Spec",
                              }
                            ]}
                          >
                            <Input
                              style={{ height: "56px", color: "#000" }}
                              className="PU-input"
                              placeholder="Spec"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24} sm={24} xs={24} md={8}>
                          <Form.Item
                            {...restField}
                            style={{ margin: "0" }}
                            name={[name, "price"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Price",
                              },
                              {
                                validator: (_, value) => {
                                  if (value <= 0) {
                                    return Promise.reject(
                                      "Price cannot be negative"
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              }
                            ]}
                          >
                            <Input
                              style={{ height: "56px", color: "#000" }}
                              className="PU-input"
                              placeholder="Price"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                      />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
          <Col span={18} sm={24} style={{ maxWidth: "1150px" }}>
            <Title level={2} style={{ color: "#00ffff" }}>Product Overview</Title>
          </Col>
          <Col span={18}>
            <Row gutter={32}>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Range *</Text>
                <Form.Item
                  name="range_Overview"
                  rules={[
                    { required: true, message: "Please enter the range!" },
                  ]}
                >
                  <Input
                    placeholder="Enter Range"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="km"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Top Speed *</Text>
                <Form.Item
                  name="topSpeed_Overview"
                  rules={[
                    { required: true, message: "Please enter the top speed!" },
                  ]}
                >
                  <Input
                    placeholder="Enter top speed"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="km/h"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Charging time *</Text>
                <Form.Item
                  name="chargingTime"
                  rules={[
                    { required: true, message: "Please enter charging time!" },
                  ]}
                >
                  <Input
                    placeholder="Enter charging time"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="hr"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Acceleration 0-40km/h *</Text>
                <Form.Item
                  name="accelaration"
                  rules={[
                    { required: true, message: "Please enter accelaration!" },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Enter accelaration"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    suffix="sec"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">
                  Battery safety and certifications *
                </Text>
                <Form.Item
                  name="batterySafetyAndCertification"
                  rules={[{ required: true, message: "Please enter details!" }]}
                >
                  <Input
                    placeholder="Enter details"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Safety *</Text>
                <Form.Item
                  name="safety"
                  rules={[{ required: true, message: "Please enter details!" }]}
                >
                  <Input
                    placeholder="Enter safety details"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Transmission *</Text>
                <Form.Item
                  name="transmission_Overview"
                  rules={[
                    {
                      required: true,
                      message: "Please enter transmission details!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter transmission"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Checkbox style={{ margin: '10px 0' }} onChange={() => setBmsCert(!bmsCert)}>BMS Certification</Checkbox>
                {bmsCert && (
                  <>
                    <Upload onRemove={onDocRemove} {...bmsProps}>
                      <Button icon={<UploadOutlined />}>Click to Upload BMS Certificate</Button>
                    </Upload>
                    {docError.bmsError !== '' && <div><Text style={{ fontSize: 12, color: 'red' }}>{docError.bmsError}</Text></div>}
                    <Text style={{ fontSize: 12 }}>* Upload document in pdf format only.</Text>
                  </>
                )}
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Checkbox style={{ margin: '10px 0' }} onChange={() => setSubsidy(!subsidy)}>Is approved for subsidy under EMPS 2024</Checkbox>
                {subsidy && (
                  <>
                    <Upload onRemove={onDocRemove} {...subsidyProps}>
                      <Button icon={<UploadOutlined />}>Click to Upload Certificate</Button>
                    </Upload>
                    {docError.subsidyError !== '' && <div><Text style={{ fontSize: 12, color: 'red' }}>{docError.subsidyError}</Text></div>}
                    <Text style={{ fontSize: 12 }}>* Upload documents in pdf format only.</Text>
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={18} sm={24} style={{ maxWidth: "1150px" }}>
            <Title level={2} style={{ color: "#00ffff" }}>Product Performance</Title>
          </Col>
          <Col span={18}>
            <Row gutter={32}>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Top Speed *</Text>
                <Form.Item
                  name="topSpeed_Performance"
                  rules={[
                    { required: true, message: "Please enter top speed!" },
                  ]}
                >
                  <Input
                    placeholder="Enter top speed"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="km/h"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Range *</Text>
                <Form.Item
                  name="range_Performance"
                  rules={[
                    { required: true, message: "Please enter the range!" },
                  ]}
                >
                  <Input
                    placeholder="Enter range"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="km"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Drive Modes *</Text>
                <Form.Item
                  name="driveModes"
                  rules={[
                    { required: true, message: "Please enter drive modes!" },
                  ]}
                >
                  <Input
                    placeholder="Enter drive modes "
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Enter Motor Type *</Text>
                <Form.Item
                  name="motorType"
                  rules={[
                    { required: true, message: "Please enter motor type!" },
                  ]}
                >
                  <Input
                    placeholder="Enter motor type"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Acceleration 0-40km/h *</Text>
                <Form.Item
                  name="accelaration"
                  rules={[
                    { required: true, message: "Please enter accelaration!" },
                  ]}
                >
                  <Input
                    placeholder="Enter accelaration"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="sec"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Transmission *</Text>
                <Form.Item
                  name="transmission_Performance"
                  rules={[
                    {
                      required: true,
                      message: "Please enter transmission details!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter transmission"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Peak Motor Power *</Text>
                <Form.Item
                  name="peakMotorPower"
                  rules={[
                    {
                      required: true,
                      message: "Please enter peak motor power!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter peak motor power "
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="kW"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Battery Type *</Text>
                <Form.Item
                  name="batteryType"
                  rules={[
                    { required: true, message: "Please enter battery type!" },
                  ]}
                >
                  <Input
                    placeholder="Enter battery type  "
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Battery Capacity *</Text>
                <Form.Item
                  name="batteryCapacity"
                  rules={[
                    {
                      required: true,
                      message: "Please enter battery capacity!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter battery capacity"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Charger Type and Capacity *</Text>
                <Form.Item
                  name="chargerType"
                  rules={[
                    { required: true, message: "Please enter charger type!" },
                  ]}
                >
                  <Input
                    placeholder="Enter charger type and capacity  "
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">
                  Motor Power kW (Nominal/Peak) *
                </Text>
                <Form.Item
                  name="motorPower"
                  rules={[
                    { required: true, message: "Please enter motor power!" },
                  ]}
                >
                  <Input
                    placeholder="Enter Motor Power kW"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="kW"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Gradeability Claim *</Text>
                <Form.Item
                  name="gradeabilityClaim"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the gradeability claim!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Gradeability Claim"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="degrees"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Torque at Motor Shaft *</Text>
                <Form.Item
                  name="torqueAtMotorShaft"
                  rules={[
                    { required: true, message: "Please enter the torque!" },
                  ]}
                >
                  <Input
                    placeholder="Enter torque at motor shaft"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="Nm"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">⁠Rated Motor Power *</Text>
                <Form.Item
                  name="ratedMotorPower"
                  rules={[
                    {
                      required: true,
                      message: "Please enter rated motor power!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter ⁠rated motor power"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="kW"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={18} sm={24} style={{ maxWidth: "1150px" }}>
            <Title level={2} style={{ color: "#00ffff" }}>Product Dimensions</Title>
          </Col>
          <Col span={18}>
            <Row gutter={32}>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Total Weight *</Text>
                <Form.Item
                  name="totalWeight"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the total weight!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter total weight"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="kg"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Boot Space *</Text>
                <Form.Item
                  name="bootSpace"
                  rules={[
                    { required: true, message: "Please enter the boot space!" },
                  ]}
                >
                  <Input
                    placeholder="Enter boot space"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="L"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Length *</Text>
                <Form.Item
                  name="length"
                  rules={[
                    { required: true, message: "Please enter the length!" },
                  ]}
                >
                  <Input
                    placeholder="Enter length "
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="mm"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Width  *</Text>
                <Form.Item
                  name="width"
                  rules={[
                    { required: true, message: "Please enter the width!" },
                  ]}
                >
                  <Input
                    placeholder="Enter width"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="mm"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Height *</Text>
                <Form.Item
                  name="height"
                  rules={[
                    { required: true, message: "Please enter the height!" },
                  ]}
                >
                  <Input
                    placeholder="Enter height"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="mm"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">⁠Seat Length *</Text>
                <Form.Item
                  name="seatLength"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the seat length!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter ⁠seat length"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="mm"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Ground Clearance</Text>
                <Form.Item name="groundClearance">
                  <Input
                    placeholder="Enter ground clearance"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="mm"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">⁠Wheelbase</Text>
                <Form.Item name="wheelbase">
                  <Input
                    placeholder="Enter ⁠Wheelbase"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    suffix="mm"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={18} sm={24} style={{ maxWidth: "1150px" }}>
            <Title level={2} style={{ color: "#00ffff" }}>Product Brakes, Wheels and Suspension</Title>
          </Col>
          <Col span={18}>
            <Row gutter={32}>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Frame Type</Text>
                <Form.Item name="frameType">
                  <Input
                    placeholder="Enter frame type"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Front Suspensions</Text>
                <Form.Item name="frontSuspensions">
                  <Input
                    placeholder="Enter front suspensions"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Rear Suspensions</Text>
                <Form.Item name="rearSuspensions">
                  <Input
                    placeholder="Enter rear suspensions"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Front Brake Type</Text>
                <Form.Item name="frontBrakeType">
                  <Input
                    placeholder="Enter front brake type"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Rear Brake Type</Text>
                <Form.Item name="rearBrakeType">
                  <Input
                    placeholder="Enter rear brake type"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">ABS / CBS *</Text>
                <Form.Item
                  name="absCbs"
                  rules={[{ required: true, message: "Please enter ABS/CBS!" }]}
                >
                  <Input
                    placeholder="Enter ⁠⁠abs / cbs"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Wheel Type *</Text>
                <Form.Item
                  name="wheelType"
                  rules={[
                    { required: true, message: "Please enter the wheel type!" },
                  ]}
                >
                  <Input
                    placeholder="Enter wheel type"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">⁠Front Tyre *</Text>
                <Form.Item
                  name="frontTyre"
                  rules={[
                    { required: true, message: "Please enter front tyre!" },
                  ]}
                >
                  <Input
                    placeholder="Enter ⁠front tyre"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Rear Tyre *</Text>
                <Form.Item
                  name="rearTyre"
                  rules={[
                    { required: true, message: "Please enter the rear tyre!" },
                  ]}
                >
                  <Input
                    placeholder="Enter ⁠rear tyre"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={18} sm={24} style={{ maxWidth: "1150px" }}>
            <Title style={{ color: "#00ffff" }} level={2}>Product Features</Title>
          </Col>
          <Col span={18}>
            <Row gutter={32}>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Proximity Lock / Unlock</Text>
                <Form.Item name="proximityLockUnlock">
                  <Input
                    placeholder="Enter proximity lock / unlock"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Remote Boot Lock / Unlock</Text>
                <Form.Item name="remoteBootLockUnlock">
                  <Input
                    placeholder="Enter remote boot lock / unlock"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">
                  Receive Call / Message / Access Contacts
                </Text>
                <Form.Item name="receiveCall_Message_AccessContacts">
                  <Input
                    placeholder="Enter details"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Infotainment</Text>
                <Form.Item name="infotainment">
                  <Input
                    placeholder="Enter Infotainment"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">⁠Side Stand Alert</Text>
                <Form.Item name="sideStandAlert">
                  <Input
                    placeholder="Enter ⁠side stand alert"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">⁠Anti-Theft Alert System</Text>
                <Form.Item name="antiTheftAlertSystem">
                  <Input
                    placeholder="Enter ⁠⁠⁠anti-theft alert system"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Geo Fencing</Text>
                <Form.Item name="geoFencing">
                  <Input
                    placeholder="Enter Geo Fencing"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">
                  WiFi / Bluetooth / GPS Connectivity
                </Text>
                <Form.Item name="wifi_bluetooth_gps_connectivity">
                  <Input
                    placeholder="Enter ⁠⁠details"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">⁠Onboard Navigation</Text>
                <Form.Item name="onboardNavigation">
                  <Input
                    placeholder="Enter ⁠onboard navigation"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Limp Home Mode</Text>
                <Form.Item name="limpHomeMode">
                  <Input
                    placeholder="Enter limp home mode"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Reverse Mode</Text>
                <Form.Item name="reverseMode">
                  <Input
                    placeholder="Enter Reverse Mode"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Hill Mode</Text>
                <Form.Item name="hillMode">
                  <Input
                    placeholder="Enter hill mode"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Get Home Mode</Text>
                <Form.Item name="getHomeMode">
                  <Input
                    placeholder="Enter get home mode"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Cruise Control</Text>
                <Form.Item name="cruiseControl">
                  <Input
                    placeholder="Enter cruise control"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Take Me Home Lights</Text>
                <Form.Item name="takeMeHomeLights">
                  <Input
                    placeholder="Enter take me home lights"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Find My Scooter</Text>
                <Form.Item name="findMyScooter">
                  <Input
                    placeholder="Enter find my scooter"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">HMI Modes with Sound</Text>
                <Form.Item name="hmiModesWithSound">
                  <Input
                    placeholder="Enter hmi modes with sound"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Welcome Screen</Text>
                <Form.Item name="welcomeScreen">
                  <Input
                    placeholder="Enter welcome screen"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">
                  OTA Updates + Forced Updates
                </Text>
                <Form.Item name="ota_force_updates">
                  <Input
                    placeholder="Enter OTA updates + forced updates"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Manual SOS</Text>
                <Form.Item name="manualSOS">
                  <Input
                    placeholder="Enter manual sos"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">
                  Book Service Appointment on App
                </Text>
                <Form.Item name="bookServiceAppointment">
                  <Input
                    placeholder="Enter details"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">
                  Track Your Vehicle on the App
                </Text>
                <Form.Item name="trackYourVehicle">
                  <Input
                    placeholder="Enter Track Your Vehicle on the App"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">
                  ⁠Road Side Assitance on the App
                </Text>
                <Form.Item name="roadSideAssistance">
                  <Input
                    placeholder="Enter ⁠Road Side Assitance on the App"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">
                  Predictive Maintenance (Brake pad Wear Sensor)
                </Text>
                <Form.Item name="predictiveMaintenance">
                  <Input
                    placeholder="Enter details"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={8}>
                <Text className="label-style">Voice Assistant</Text>
                <Form.Item name="voiceAssistant">
                  <Input
                    placeholder="Enter Voice Assistant"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={18}>
            <Row gutter={32}>
              <Col style={{ margin: "20px 0" }}>
                <Button className="cancel-btn" onClick={() => { }}>
                  Cancel
                </Button>
              </Col>
              <Col style={{ margin: "20px 10px" }}>
                <Button
                  className="save-btn"
                  loading={isLoading}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default ProductUpload;
