import React from 'react';
import { Row, Col, Typography, Image } from 'antd';

//@ts-ignore
import vector15 from '../../assets/images/Vector15.svg';
import './visionStyle/thirdSection.css';

const { Text } = Typography;

const ThirdSection = () => {
	return (
		<Row
			className="vision-third-section-container"
			style={{ alignItems: 'center' }}
		>
			<Col xs={24} md={14} lg={12} className="vision-third-left-container">
				<Text className="vision-third-title">
					Revolutionising Mobility for India
				</Text>
				<Text className="vision-third-sub-title">
					All the Best EVs Under One Roof:{' '}
				</Text>
				<Text className="vision-third-description">
					Lilypad brings India's top EV brands together, simplifying your search
					for the perfect electric vehicle. Compare, choose, and embrace
					sustainable mobility with ease.
				</Text>
				<Text className="vision-third-sub-title">
					Know Your Ride Inside Out:{' '}
				</Text>
				<Text className="vision-third-description">
					Our platform demystifies EV technology and market trends, equipping
					you with the knowledge to make confident, informed choices.
				</Text>
				<Text className="vision-third-sub-title">Seamless Experience: </Text>
				<Text className="vision-third-description">
					From discovery to purchase, Lilypad offers a seamless, hassle-free &
					user-friendly journey towards owning your ideal EV.
				</Text>
			</Col>
			<Col xs={24} md={10} lg={12} className="vision-third-right-container">
				<Image
					src="./../images/our-commitment.webp"
					width={400}
					preview={false}
				/>
			</Col>
		</Row>
	);
};

export default ThirdSection;
