import { Row, Col, Image } from 'antd'

//@ts-ignore
import "./visionStyle/fifthSection.css"
//@ts-ignore
import vector28 from "../../assets/images/blue scooty front 5.png"
//@ts-ignore
import vector29 from "../../assets/images/blue scooty front 6.png"
//@ts-ignore
import vector45 from "../../assets/images/extinguisher mockup 4.png"
//@ts-ignore
import vector46 from "../../assets/images/extinguisher mockup.png"
//@ts-ignore
import vector33 from "../../assets/images/Free Cap with Patch Mockup.png"
//@ts-ignore
import vector38 from "../../assets/images/scooty wordmark.png"


const SeventhSection = () => {
    return (
        <div className='vision-fifth-section-container'>
            <Row>
                <Col xs={24} md={12} lg={11} className=''>
                    <Image src={vector28} preview={false} className='vison-images'/>
                    <Image src={vector29} preview={false} className='vison-images'/>
                </Col>
                <Col xs={0} md={12} lg={12} className='vision-fifth-col-2'>
                    <Image src={vector38} preview={false} width={'525px'}/>
                </Col>
                <Col span={24} style={{padding:'20px'}}>
                    <Row>
                        <Col span={8}>
                            <Image src={vector45} preview={false} className='bottom-imgs' />
                        </Col>
                        <Col span={8}>
                            <Image src={vector46} preview={false} className='bottom-imgs' />
                        </Col>
                        <Col span={8}>
                            <Image src={vector33} preview={false} className='bottom-imgs' />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default SeventhSection