import orderApiService from "./orderApiService";
import authApiService from "./authApiService";
import axios from "axios";
import { message } from "antd";

const blogRegister = async (tokenObj) => {
  try {
    console.info(tokenObj)
    let register;
    register = await axios.post(
      `${process.env.REACT_APP_LILYPAD_BLOG_SITE_API_URL}/register?username=${tokenObj.email ? tokenObj.email : tokenObj.waNumber}&password=${tokenObj.email ? tokenObj.email : tokenObj.waNumber}&email=`,
      {
        username: tokenObj.email ? tokenObj.email : tokenObj.waNumber,
        password: tokenObj.email ? tokenObj.email : tokenObj.waNumber,
        email: '',
      },
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    return {
      error: false,
      message: "",
      // data: response,
    };
  } catch (error) {
    if (error.response.data.code === 406) {
      const { data } = await axios.post(
        `${process.env.REACT_APP_LILYPAD_BLOG_SITE_API_URL}/login?user_login=${tokenObj.email ? tokenObj.email : tokenObj.waNumber}&user_password=${tokenObj.email ? tokenObj.email : tokenObj.waNumber}`,
        {
          username: tokenObj.email ? tokenObj.email : tokenObj.waNumber,
          password: tokenObj.email ? tokenObj.email : tokenObj.waNumber,
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (data) {
        // return response;
      }
    } else {
      message.error("failed to login to blog site");
    }
  }
};

export const userLogin = async (tokenObj) => {
  try {
    const response = await authApiService.post(
      `/auth/otpless-user-login`,
      tokenObj
    );
    if (response) {
      blogRegister(tokenObj);
    }
    return response;
  } catch (error) {
    // console.error("Failed to login!");
    return [];
  }
};

export const getPincodesForAllStates = async (body) => {
  try {
    const response = await authApiService.post(
      "/auth/getPincodeForAllStates",
      body
    );
    return response.data.data;
  } catch (error) {
    // console.error("Error fetching pincodes");
    return [];
  }
};
export const updateUserDetails = async (userDetails, id) => {
  return authApiService.put(`/auth/user/update-profile/${id}`, userDetails);
};
export const getUserCartList = async () => {
  try {
    const response = await orderApiService.get("/cart/item");
    return response.data.data; // Assuming the data is returned in the response property named 'data'
  } catch (error) {
    // Handle the error here
    // console.error("Error fetching user cart list:", error);
    // You can choose to throw the error further or return a default value
    // throw error; // Throw the error to handle it elsewhere or use a default return value
    return []; // Return an empty array or any default value
  }
};
export const getUserAddress = async () => {
  try {
    const response = await orderApiService.get("/address");
    return response.data.data; // Assuming the data is returned in the response property named 'data'
  } catch (error) {
    // Handle the error here
    // console.error("Error fetching user address list:", error);
    // You can choose to throw the error further or return a default value
    // throw error; // Throw the error to handle it elsewhere or use a default return value
    return []; // Return an empty array or any default value
  }
};

export const addAddress = async (addressObj) => {
  try {
    const response = await orderApiService.post("/address", addressObj);
    return response.data; // Assuming the data is returned in the response property named 'data'
  } catch (error) {
    // Handle the error here
    // console.error("Error saving user address:", error);
    // You can choose to throw the error further or return a default value
    // throw error; // Throw the error to handle it elsewhere or use a default return value
    return []; // Return an empty array or any default value
  }
};

export const getAddress = async (addressId) => {
  try {
    const response = await orderApiService.get(`/address/${addressId}`);
    return response.data;
  } catch (error) {
    // console.error("Error saving user address:", error);
    return [];
  }
};

export const editAddress = async (addressObj) => {
  try {
    const response = await orderApiService.put("/address", addressObj);
    return response.data;
  } catch (error) {
    // console.error("Error editing user address:", error);
    return null;
  }
};

export const deleteAddress = async (payload) => {
  try {
    const response = await orderApiService.delete(`/address/${payload.id}`);
    return response.data;
  } catch (error) {
    // console.error("Error deleting user address:", error);
    return null;
  }
};

export const addProductToCart = async (productData) => {
  return orderApiService.post("/cart/item", productData);
};

export const deleteProductFromCart = async (productId) => {
  try {
    const response = await orderApiService.delete(`/cart/item/${productId.id}`);
    return response.data;
  } catch (error) {
    // console.error("Delete Error:", error);
    throw error;
  }
};
export const deleteAllProductFromCart = async () => {
  try {
    const response = await orderApiService.delete(`/cart/item`);
    return response.data;
  } catch (error) {
    // console.error("Delete Error:", error);
    throw error;
  }
};
export const updateProductFromCart = async (payload) => {
  try {
    const response = await orderApiService.put(`/cart/item`, payload);
    return response.data;
  } catch (error) {
    // console.error("update Error:", error);
    throw error;
  }
};

export const sendEmail = async (payload) => {
  try {
    const response = await authApiService.post("/auth/email/send", payload);
    return response.data;
  } catch (error) {
    // console.error("Error while sending a mail!", error);
    return [];
  }
};

export const userLogout = async () => {
  try {
    const { data } = await authApiService.post("/auth/logout");
    if (data) {
      return data;
    }
  } catch (error) {
    // console.error("Error while loggin out", error);
    return null;
  }
};

export const kycLogin = async () => {
  try {
    const response = await authApiService.get("/auth/kyc/login");
    return response.data;
  } catch (error) {
    // console.error("Error while trying kyc registration!", error);
    return [];
  }
};
