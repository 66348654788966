import React from 'react';
import { Row, Col, Typography, Image } from 'antd';

//@ts-ignore
import vector14 from '../../assets/images/Vector14.svg';
import './visionStyle/secondSection.css';

const { Paragraph } = Typography;

const SecondSection = () => {
	return (
		<Row className="vision-second-section-container">
			<Col xs={24} md={16} lg={12} className="vision-second-left-container">
				<Paragraph className="vision-second-title">
					Leveraging India’s Thriving EV Ecosystem
				</Paragraph>
				<Paragraph className="vision-second-description">
					In India, our shared vision for cleaner, quieter streets is driving us
					towards an electric future. With the right support from the government
					and a collective ambition for sustainability, electric vehicles have
					become a symbol of progress. At Lilypad, this dream fuels our passion—
					commitment of a nation ready to embrace change.{' '}
				</Paragraph>
			</Col>
			<Col xs={24} md={8} className="vision-second-right-container">
				<Image
					src="./../images/visionHand.webp"
					preview={false}
					className="vision-hands-img"
				/>
			</Col>
		</Row>
	);
};

export default SecondSection;
