import React, { useEffect, useState } from "react";
import { Row, Typography } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import ShippingSection from "../Components/CheckOut/ShippingSection";
import OrderSummary from "../Components/CheckOut/OrderSummary";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

const ShippingSectionPage = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { Text } = Typography;
  const navigate = useNavigate();
  const [form] = useForm();
  form.setFieldsValue({
    contact: userDetails?.mobile || userDetails?.email,
  });

  const checkUserLoggedIn = () => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    if (!otpless_user_token) {
      navigate("/products");
    }
  };
  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    const handleReload = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("reload", handleReload);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("reload", handleReload);
    };
  }, [windowWidth]);

  const getOrderTotalAmount = (amount) => {
    // don't delete this function
  }

  return (
    <>
      <div
        onClick={() => navigate(-1)}
        style={{ margin: '20px', display: 'flex', width: '140px' }}
      >
        <Text className="back-btn-text" style={{ height: '40px' }}>
          {' '}
          <LeftOutlined className="product-detail-back-btn-icon" />
          Back
        </Text>
      </div>
      <Row gutter={16} type="flex" justify="center" align="middle" style={{ margin: 0 }}>
        <ShippingSection />
        {windowWidth > 800 && (
          <OrderSummary getOrderTotalAmount={getOrderTotalAmount} />
        )}
      </Row>
    </>
  );
};

export default ShippingSectionPage;
