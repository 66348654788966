import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Button, Card } from "antd"
import "./homeStyle/promotion.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "../../assets/4.png"

const Promotion = () => {
    const sliderRef = useRef(null);

    const slickSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        arrows: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1354,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    };

    const navigate = useNavigate();

    const productData = [
        {
            "id": "2377397c-2eab-4510-af24-3dbce8eb9f64",
            "brandName": "Herald E Bike",
            "color": "Red,Black,White,Purple",
            "colorSearchName": "red,black,white,purple",
            "motorPower": 0.25,
            "motorType": "BLDC Motor Waterproof",
            "name": "Rider",
            "productImages": "https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Rider%20Red%20(2).png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Rider%20White.png",
            "sellingPrice": 52838,
            "topSpeed_Overview": 25
        },
        {
            "id": "6f369e9e-9c2d-4a55-988a-e91165f0bf6a",
            "brandName": "Herald E Bike",
            "color": "White,Black,Blue,Red,Gray",
            "colorSearchName": "white,black,blue,red,gray",
            "motorPower": 0.25,
            "motorType": "BLDC Motor Waterproof",
            "name": "Rider Pro",
            "productImages": "https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Rider%20Pro%20Black%20(1).png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Rider%20Pro%20(1).png",
            "sellingPrice": 66353,
            "topSpeed_Overview": 25
        },
        {
            "id": "9c5efbcf-378d-49a0-b7da-1dc891796bf8",
            "brandName": "Herald E Bike",
            "color": "Black",
            "colorSearchName": "black",
            "motorPower": 0.25,
            "motorType": "BLDC Motor Waterproof",
            "name": "Lifter 250",
            "productImages": "https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Lifter%20250%20black.png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/LIFTER%20250.png",
            "sellingPrice": 88781,
            "topSpeed_Overview": 25
        },
        {
            "id": "a775cc83-5c4f-4580-b73c-c5fd33b80251",
            "brandName": "Herald E Bike",
            "color": "White,Black,Blue,Red,Gray",
            "colorSearchName": "white,black,blue,red,gray",
            "motorPower": 0.25,
            "motorType": "BLDC Motor Waterproof",
            "name": "Royal",
            "productImages": "https://lilypad-product-images.s3.ap-south-1.amazonaws.com/royal%201.png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/royal%201.png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/royal%20red%20(2).png",
            "sellingPrice": 58290,
            "topSpeed_Overview": 25
        },
        {
            "id": "9d2d655a-deac-476b-8cab-b233eb5c67ba",
            "brandName": "Herald E Bike",
            "color": "Black,White,Gray,Blue,Red",
            "colorSearchName": "black,white,gray,blue,red",
            "motorPower": 1.2,
            "motorType": "BLDC Motor Waterproof",
            "name": "Royal Dlx",
            "productImages": "https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Royal%20dlx%20grey.png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Royal%20Dlx.png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/royal%20dlx%20black.png",
            "sellingPrice": 61600,
            "topSpeed_Overview": 60
        },
        {
            "id": "ac854380-fa9f-485b-bd6e-dd619bdc46e1",
            "brandName": "Herald E Bike",
            "color": "Red,Black,Gray,White,Yellow",
            "colorSearchName": "red,black,gray,white,yellow",
            "motorPower": "'-1",
            "motorType": "BLDC Motor Waterproof",
            "name": "Legend Prime",
            "productImages": "https://lilypad-product-images.s3.ap-south-1.amazonaws.com/legend%20prime%20red.png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/legend%20prime%20green.png",
            "sellingPrice": 72272,
            "topSpeed_Overview": 25
        },
        {
            "id": "65d899c8-4a8e-43a9-9c63-dda64db9cbb6",
            "brandName": "Herald E Bike",
            "color": "White,Black,Gray,Blue,Red",
            "colorSearchName": "white,black,gray,blue,red",
            "motorPower": 1.5,
            "motorType": "BLDC Motor Waterproof",
            "name": "Royal Pro",
            "productImages": "https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Royal%20Pro%20White.png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Royal%20Pro%20Black.png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Royal%20Pro%20Red%20(2).png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Royal%20Pro%20Purple.png",
            "sellingPrice": 68466,
            "topSpeed_Overview": 60
        },
        {
            "id": "01561d3e-4c32-4698-b4be-b18eb71a1ea7",
            "brandName": "Herald E Bike",
            "color": "White,Black,Gray",
            "colorSearchName": "white,black,gray",
            "motorPower": 0.25,
            "motorType": "BLDC Motor Waterproof",
            "name": "Aura",
            "productImages": "https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Aura(1).png",
            "sellingPrice": 68013,
            "topSpeed_Overview": 25
        },
        {
            "id": "45ed17ca-2464-42a4-b5dc-1762bda0e642",
            "brandName": "Herald E Bike",
            "color": "Red,Black,White,Purple,Silver",
            "colorSearchName": "red,black,white,purple,silver",
            "motorPower": 3,
            "motorType": "BLDC Motor Waterproof",
            "name": "Style",
            "productImages": "https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Style.png",
            "sellingPrice": 72576,
            "topSpeed_Overview": 25
        },
        {
            "id": "695e2178-b4dc-4210-b99b-cc27d1240375",
            "brandName": "Herald E Bike",
            "color": "White,Black,Blue,Red,Gray,Yellow",
            "colorSearchName": "white,black,blue,red,gray,yellow",
            "motorPower": 0.25,
            "motorType": "BLDC Motor Waterproof",
            "name": "Legend Super",
            "productImages": "https://lilypad-product-images.s3.ap-south-1.amazonaws.com/legend%20super%20silver%20bb.png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/LEGEND%20SUPER%20YELLOW.png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/legend%20super%20red.png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/legend%20super%204.png",
            "sellingPrice": 60502,
            "topSpeed_Overview": 25
        },
        {
            "id": "38875966-8192-4382-871b-356578e4b0cc",
            "brandName": "Herald E Bike",
            "color": "White,Black,Gray,Blue,Red",
            "colorSearchName": "white,black,gray,blue,red",
            "motorPower": 1.5,
            "motorType": "BLDC Motor Waterproof",
            "name": "Legend Pro",
            "productImages": "https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Legend%20pro%20white%20(2).png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Legend%20PRo%20red%206.png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/Legend%20Pro%20Gray%20(2).png",
            "sellingPrice": 70743,
            "topSpeed_Overview": 60
        },
        {
            "id": "205e4106-45ea-4e9e-a999-e58a369cfae5",
            "brandName": "Herald E Bike",
            "color": "Brown,Blue",
            "colorSearchName": "brown,blue",
            "motorPower": 0,
            "motorType": 90,
            "name": "Test",
            "productImages": "https://lilypad-product-images.s3.ap-south-1.amazonaws.com/download.jpeg",
            "sellingPrice": 1010,
            "topSpeed_Overview": 90
        },
        {
            "id": "56d5a883-9b57-4071-a882-0be7ad4ea469",
            "brandName": "Herald E Bike",
            "color": "Red,Black,Green,Purple",
            "colorSearchName": "red,black,Green,purple",
            "motorPower": 0.25,
            "motorType": "BLDC Motor Waterproof",
            "name": "Royal Prime",
            "productImages": "https://lilypad-product-images.s3.ap-south-1.amazonaws.com/royal%20prime%20(1).png,https://lilypad-product-images.s3.ap-south-1.amazonaws.com/royal%20prime%20parrot%20green.png",
            "sellingPrice": 70701,
            "topSpeed_Overview": 25
        }
    ]

    return (
        <Row className='promotion-section-container' style={{ alignItems: 'center', justifyContent: "space-between", position: "relative" }}>
            <Col xs={24} sm={24} md={12} lg={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                <div class="shopping-image">
                    <img src={Banner} alt="shopping" style={{ width: "70%", objectFit: "cover", objectPosition: "center" }} />

                </div>

            </Col>

            <Col xs={24} sm={24} md={12} lg={16} className='promotion-right-container'>
                <Row>
                    <Col xs={24}>
                        <Slider ref={sliderRef} {...slickSettings} className="promotion-slider-horizontal" >
                            {productData.map((testimonial, index) => (

                                <div className="promotion-card-wrapper" onClick={() => navigate(`/product/${testimonial.id}`)} >
                                    <p class="ribbon">
                                        <span class="text">{testimonial.name}</span>
                                    </p>
                                    <div className='promotion-image-container'>

                                        {/* <span className='product-title' style={{ lineHeight: "10px" }}>{testimonial.productName}</span> */}
                                        <img loading='lazy' src={testimonial?.productImages?.split(",")[0]} alt={testimonial.productName} className='promotion-card-avatar' />
                                        <img loading='lazy' src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQk7uYsedHr4TVHArAJau3j3RyOERxgg6_6A&s"} alt={testimonial.productName} className='brand-image' />
                                    </div>
                                    <div className='promotion-text-overlay'>
                                        <span className='first-title-content'>₹ {testimonial.sellingPrice?.toLocaleString()}</span>
                                        <span >{testimonial.motorPower * 100} Watts Motor</span>
                                        <span style={{ lineHeight: "5px" }}>{testimonial.name} </span>
                                        <span >{testimonial.brandName} </span>
                                        <span >{testimonial.topSpeed_Overview}km/h </span>
                                        <div style={{ display: 'flex', gap: "5px" }}>
                                            {
                                                testimonial?.color?.split(",")?.map((item) => {
                                                    return <div style={{ width: "15px", height: "15px", borderRadius: "100%", transition: "all 2s", backgroundColor: item, border: `3px solid #1890ff`, boxShadow: `0px 0px 0px 1px ${item}` }}></div>

                                                })
                                            }
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0px" }} className='first-explore-btn-book'>
                                            <p >Book @ ₹ 999</p>
                                        </div>




                                    </div>

                                </div>

                            ))}
                        </Slider>
                    </Col>
                    <Col sm={0} xs={24} style={{ display: "flex", justifyContent: "end", padding: "0px 50px", marginTop: "10px" }} >
                        <Button
                            className="btn-prev"
                            onClick={() => sliderRef.current.slickPrev()}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.165 12.0066L13.1634 12.3607C13.1513 13.7652 13.0666 15.0183 12.9206 15.8124C12.9206 15.8267 12.7613 16.6143 12.6599 16.8764C12.5006 17.2554 12.2126 17.577 11.8515 17.7808C11.5624 17.9262 11.2592 18 10.9417 18C10.6922 17.9884 10.2806 17.8629 9.98692 17.7576L9.74283 17.664C8.12612 17.0218 5.03561 14.9234 3.85199 13.6402L3.76473 13.5505L3.37527 13.1302C3.12982 12.8085 3 12.4153 3 11.9923C3 11.6134 3.11563 11.2344 3.3469 10.9282C3.41614 10.829 3.52766 10.7017 3.62693 10.5942L4.006 10.1974C5.31046 8.87572 8.13485 7.02183 9.59883 6.40756C9.59883 6.39435 10.5086 6.01432 10.9417 6H10.9995C11.6639 6 12.2846 6.37892 12.6021 6.99137C12.6888 7.1588 12.772 7.48678 12.8352 7.77483L12.949 8.31871C13.0788 9.19332 13.165 10.535 13.165 12.0066ZM19.4967 10.4817C20.3269 10.4817 21 11.1613 21 11.9996C21 12.8378 20.3269 13.5175 19.4967 13.5175L15.7975 13.1903C15.1463 13.1903 14.6183 12.6583 14.6183 11.9996C14.6183 11.3419 15.1463 10.8088 15.7975 10.8088L19.4967 10.4817Z" fill="#CBD1DC" />
                            </svg>
                        </Button>
                        <Button
                            className="btn-next"
                            onClick={() => sliderRef.current.slickNext()}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.835 12.0066L10.8366 12.3607C10.8487 13.7652 10.9334 15.0183 11.0794 15.8124C11.0794 15.8267 11.2387 16.6143 11.3401 16.8764C11.4994 17.2554 11.7874 17.577 12.1485 17.7808C12.4376 17.9262 12.7408 18 13.0583 18C13.3078 17.9884 13.7194 17.8629 14.0131 17.7576L14.2572 17.664C15.8739 17.0218 18.9644 14.9234 20.148 13.6402L20.2353 13.5505L20.6247 13.1302C20.8702 12.8085 21 12.4153 21 11.9923C21 11.6134 20.8844 11.2344 20.6531 10.9282C20.5839 10.829 20.4723 10.7017 20.3731 10.5942L19.994 10.1974C18.6895 8.87572 15.8652 7.02183 14.4012 6.40756C14.4012 6.39435 13.4914 6.01432 13.0583 6H13.0005C12.3361 6 11.7154 6.37892 11.3979 6.99137C11.3112 7.1588 11.228 7.48678 11.1648 7.77483L11.051 8.31871C10.9212 9.19332 10.835 10.535 10.835 12.0066ZM4.50325 10.4817C3.67308 10.4817 3 11.1613 3 11.9996C3 12.8378 3.67308 13.5175 4.50325 13.5175L8.20248 13.1903C8.85375 13.1903 9.38174 12.6583 9.38174 11.9996C9.38174 11.3419 8.85375 10.8088 8.20248 10.8088L4.50325 10.4817Z" fill="#CBD1DC" />
                            </svg>
                        </Button>
                    </Col>

                </Row>
            </Col >



        </Row >
    )
}

export default Promotion
