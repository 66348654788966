import React from "react";
import { Layout, Row, Col, Input, Button, Space, Dropdown } from "antd";
import {
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { debounce } from "../../utils/debounce";
import ProductSearchInput from "./ProductSearchInput";

const { Header } = Layout;

const ProductListHeader = ({ onTextEntered, onSortingChange }) => {
  const handleMenuClick = (e) => {
    onSortingChange(e.key);
  };
  const items = [
    {
      label: "Price: Low to High",
      key: "asc",
      icon: <SortAscendingOutlined />,
    },
    {
      label: "Price: High to Low",
      key: "desc",
      icon: <SortDescendingOutlined />,
    },
    {
      label: "Newest Arrivals",
      key: "date",
      icon: <></>,
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  const handleInputChange = (e) => {
    var search_text = e.target.value
    var final_search_text = search_text.trim();
    onTextEntered(final_search_text);
    // Access the new value of the input through e.target.value
  };
  return (
    <Header style={{ background: "white", height: "auto", padding: "8px 0" }}>
      <Row align="middle" justify="space-between">
        {/* <Col xs={24} sm={24} lg={8}>
          <div style={{ textAlign: "center", marginBottom: "8px" }}>
            <h1>Electric Product for you</h1>
          </div>
        </Col> */}
        <Col xs={24} sm={24} lg={8}>
          <Col xs={24} md={24} lg={8} className="product-offer-banner-middle">
            <ProductSearchInput onTextEntered={onTextEntered} />
          </Col>
        </Col>
        {/* <Col xs={24} sm={12} lg={8}>
          <div style={{ textAlign: "center", marginTop: "8px" }}>
            <Space>
              <Dropdown menu={menuProps}>
                <Button>
                  <Space>
                    Sort by
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Space>
          </div>
        </Col> */}
      </Row>
    </Header>
  );
};

export default ProductListHeader;
