import { Rate } from 'antd';
import { useEffect, useState } from 'react';

const RatingFilter = ({ onRatingSelected, isResetFilter }) => {
	const [rateValue, setRateValue] = useState(0);

	const onRatingClick = (value) => {
		setRateValue(value);
		onRatingSelected(value);
	};

	useEffect(() => {
		if (isResetFilter) {
			setRateValue(0);
		}
	}, [isResetFilter]);

	return (
		<div
			style={{
				marginBottom: '16px',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<h4
				style={{
					fontFamily: 'AvenirNextLTPro',
					fontSize: '16px',
					color: '#fff',
				}}
			>
				Rating
			</h4>
			<span>
				<Rate
					value={rateValue}
					onChange={(value) => onRatingClick(value)}
					style={{ color: '#FF8F28' }}
				/>
			</span>
		</div>
	);
};
export default RatingFilter;
