import axios from 'axios';
import store from '../store'; // Import your Redux store
import { authModal } from '../utils/initOtpless';

const orderApiService = axios.create({
	//   baseURL: "http://13.235.239.143:5001/v1/api/order", // Your API base URL
	// baseURL: 'https://r36j3yytzi.execute-api.ap-south-1.amazonaws.com/prod/v1/api',
	baseURL:
		process.env.NODE_ENV === 'development'
			? 'http://13.235.239.143:5001/v1/api/order'
			: 'https://api.lilypad.co.in/v1/api/order',
});

// Request interceptor to include auth token in specific requests
orderApiService.interceptors.request.use(
	(config) => {
		// const authToken = store.getState().auth.authToken;
		let authToken = localStorage.getItem('auth_token');

		if (authToken) {
			// Check if the request URL requires authorization (e.g., cart-related endpoints)
			if (
				config.url.includes('/cart/') ||
				config.url.includes('/address') ||
				config.url.includes('order')
			) {
				config.headers['Authorization'] = `Bearer ${authToken}`;
			}
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

orderApiService.interceptors.response.use(
	(response) => {
		// Check if response status is 403 (Forbidden)
		if (response.status === 403) {
			// Prompt the user to log in again
			// Implement your logic here, such as showing a notification or redirecting to the login page
			alert('Your session has expired. Please log in again.');
		}
		return response;
	},
	({ response }) => {
		if (response.status === 403) {
			// Prompt the user to log in again
			// Implement your logic here, such as showing a notification or redirecting to the login page
			authModal();
		}
		// return Promise.reject(response);
	}
);

export default orderApiService;
