import { Typography, Image, Row, Col } from 'antd';
//@ts-ignore
import image1 from '../../assets/images/image1.svg';
//@ts-ignore
import image2 from '../../assets/images/Image2.svg';
import './aboutStyle/fifthSection.css';
import './aboutStyle/fourthSection.css';
const { Text, Paragraph } = Typography;

const contentArray = [
	{
		image: './../../images/about-1.webp',
		title: 'Aaryan Sharma',
		description:
			"A Web3 and crypto angel investor for the past four years, Aaryan has always had a keen eye for emerging opportunities. Currently he’s focused on advancing & building India's EV and renewable energy infrastructure. ",
		subDesc:
			'He is experienced in transformative technologies like Tensor and Helius, and an investor in one of the giants in the EV space— a major ride sharing company.',
	},
	{
		image: './../../images/about-2.webp',
		title: 'Uday Parmar',
		description:
			'With over 20 years of experience with consulting firms like EY and KPMG, along with multiple technology startups— Uday is now following his passion for building, disrupting & bringing digital transformation. He is passionate towards creating a positive impact on people, business, and business processes.',
		subDesc:
			'He has an extensive background & expertise in sales, partner development and corporate strategy, and is now on a mission to change the way India moves!',
	},
	// Add more objects for additional content
];

const FifthSection: React.FC = () => (
	<>
		<Row className="about-founder-section-container">
			<Col span={24} className="about-founder-container">
				<Paragraph className="about-founder-title">
					Meet the Founders{' '}
				</Paragraph>

				<Paragraph
					className="about-founder-description"
					style={{ textTransform: 'capitalize' }}
				>
					Who are on a mission to Electrify India’s Roads
				</Paragraph>
			</Col>
		</Row>
		<Row>
			<Col span={24}>
				{contentArray.map((content, index: number) => (
					<div key={index} className="container">
						{index % 2 === 0 ? (
							<>
								<div className="content-left">
									<Image
										src={content.image}
										preview={false}
										className="image-class"
									/>
								</div>
								<div className="text-right">
									<Text className="about-title" style={{ color: '#fff' }}>
										{content.title}
									</Text>
									<Text className="about-description">
										{content.description}
									</Text>
									<Text className="about-description">{content.subDesc}</Text>
								</div>
							</>
						) : (
							<>
								<div className="text-left">
									<Text className="about-title" style={{ color: '#fff' }}>
										{content.title}
									</Text>
									<Text className="about-description">
										{content.description}
									</Text>
									<Text className="about-description">{content.subDesc}</Text>
								</div>
								<div className="content-right">
									<Image
										src={content.image}
										preview={false}
										className="image-class"
									/>
								</div>
							</>
						)}
					</div>
				))}
			</Col>
		</Row>
	</>
);

export default FifthSection;
