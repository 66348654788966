import { Slider } from 'antd';
import { useEffect, useState } from 'react';

const PriceFilter = ({ onPriceSelected, isResetFilter, maxPrice }) => {
	const minPrice = 0; // 1k
	// const maxPrice = 100; // 100k

	const [defaultValue, setDefaultValue] = useState([minPrice, maxPrice]);
	const [sliderKey, setSliderKey] = useState(0);

	const onPriceChange = (value) => {
		onPriceSelected(value);
	};
	const formatPrice = (value) => {
		return `${value}K`;
	};

	useEffect(() => {
		if (isResetFilter) {
			setSliderKey((prevKey) => prevKey + 1);
		}
	}, [isResetFilter]);

	return (
		<div>
			<h4 className="filter-header">Filter by price</h4>
			<p hidden>{sliderKey}</p>
			<Slider
				range
				key={sliderKey}
				defaultValue={defaultValue}
				min={minPrice}
				max={maxPrice}
				className="custom-slider"
				onChangeComplete={onPriceChange}
				tipFormatter={(value) => formatPrice(value)}
			/>
			<p
				style={{
					color: '#707070',
					fontFamily: 'AvenirNextLTPro',
					fontWeight: '600',
				}}
			>{`Price ${minPrice} - ${maxPrice?.toFixed(0)}K`}</p>
		</div>
	);
};
export default PriceFilter;
