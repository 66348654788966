import React from 'react'
import './style.css'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

const ProductFeatures = ({ productData }) => {
    return (
        <>
            <table className='overview-table'>
                <tr>
                    <td>&#9679; Proximity Lock / Unlock</td>
                    <td>{productData.proximityLockUnlock ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Remote Boot Lock / Unlock</td>
                    <td>{productData.remoteBootLockUnlock ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Receive Call / Message / Access Contacts</td>
                    <td>{productData.receiveCall_Message_AccessContacts ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Infotainment</td>
                    <td>{productData.infotainment ? productData.infotainment : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Side Stand Alert</td>
                    <td>{productData.sideStandAlert ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Anti-theft Alert System</td>
                    <td>{productData.antiTheftAlertSystem ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Geo Fencing</td>
                    <td>{productData.geoFencing ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; WiFi / Bluetooth / GPS Connectivity</td>
                    <td>{productData.wifi_bluetooth_gps_connectivity ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Onboard Navigation</td>
                    <td>{productData.onboardNavigation ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Limp Home Mode</td>
                    <td>{productData.limpHomeMode ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Reverse Mode</td>
                    <td>{productData.reverseMode ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Hill Mode</td>
                    <td>{productData.hillMode ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Get Home Mode</td>
                    <td>{productData.getHomeMode ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Cruise Control</td>
                    <td>{productData.cruiseControl ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Take Me Home Lights</td>
                    <td>{productData.takeMeHomeLights ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Find my scooter</td>
                    <td>{productData.findMyScooter ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; HMI Modes with Sound</td>
                    <td>{productData.hmiModesWithSound ? productData.hmiModesWithSound : '-'}</td>
                </tr>
                <tr>
                    <td>&#9679; Welcome Screen</td>
                    <td>{productData.welcomeScreen ? productData.welcomeScreen : '-'}</td>
                </tr>
                <tr>
                    <td>&#9679; OTA updates + forced updates</td>
                    <td>{productData.ota_force_updates ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Manual sos</td>
                    <td>{productData.manualSOS ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Book service appointment on app</td>
                    <td>{productData.bookServiceAppointment ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Track Your Vehicle on the App</td>
                    <td>{productData.trackYourVehicle ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Road Side Assitance on the App</td>
                    <td>{productData.roadSideAssistance ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Predictive Maintenance (Brake pad Wear Sensor)</td>
                    <td>{productData.predictiveMaintenance ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
                <tr>
                    <td>&#9679; Voice Assistant</td>
                    <td>{productData.voiceAssistant ? <CheckCircleOutlined className='checked-icon' /> : <CloseCircleOutlined className='closed-icon' />}</td>
                </tr>
            </table>
        </>
    )
}

export default ProductFeatures