import {
  Row,
  Col,
  Typography,
  Input,
  Button,
  Image,
  Form,
  message,
  Spin,
} from "antd";
import {
  MailOutlined,
  GlobalOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";

//@ts-ignore
import vector23 from "../../assets/images/Free Building Wall Mockup.png";
import TextArea from "antd/es/input/TextArea";
import "./contactStyle/firstSection.css";
import { useForm } from "antd/lib/form/Form";
import { sendEmail } from "../../api/user";
import { useState } from "react";

const { Text } = Typography;

const FirstSection = () => {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (body: any) => {
    setIsLoading(true);
    try {
      let mailData = {
        from: "support@lilypad.co.in",
        to: "support@lilypad.co.in",
        subject: "Contact Us Request",
        html: `<p> Hi Team,  <br />

        A user is trying to reach us for support, here are the details of that user: <br/>
        email: ${body?.email},
        name: ${body?.name}
        mobile: ${body?.mobile}
        message: ${body?.message}
        </p>`,
      };
      const data = await sendEmail(mailData);
      if (data.error === false) {
        form.resetFields();
        message.success("We have sent you a mail :)");
        setIsLoading(false);
      }
    } catch (error) {
      message.error("Failed to submit contact form!");
      setIsLoading(false);
    }
  };
  return (
    <Row className="contact-first-section-container">
      <Col xs={24} md={12} lg={12} className="contact-first-left-container">
        <div className="contact-first-left-top-container">
          <div style={{ margin: "0 0 15px 0" }}>
            <Text className="contact-first-sub-title">CONTACT US</Text>
          </div>
          <div style={{ margin: "10px 0 15px 0" }}>
            <Text className="contact-first-title">
              We would love to hear from you.
            </Text>
          </div>
          <div style={{ margin: "8px 0" }}>
            <Text className="contact-first-description">
              Fill up the form and our team will get back to you within 24 hours
            </Text>
          </div>
        </div>
        <div className="contact-first-left-bottom-container">
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Please enter your name!" },
                { pattern: new RegExp("^[a-zA-Z ]+$"), message: "please enter only alphabets"}
              ]}
            >
              <Input className="contact-input" placeholder="Full Name" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please enter your email!" },
                {
                  pattern: new RegExp("^([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$"),
                  message: "please enter correct email",
                }
              ]}
            >
              <Input className="contact-input" placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="number"
              rules={[
                { required: true, message: "Please enter your number!" },
                {
                  pattern: new RegExp("^[0-9]{12}$"),
                  message: "please enter correct mobile!",
                }
              ]}
            >
              <Input className="contact-input" placeholder="Please add 91 as prefix for mobile number" />
            </Form.Item>
            <Form.Item
              name="message"
              rules={[
                { required: true, message: "Please enter a message!" },
              ]}
            >
              <TextArea className="contact-input" placeholder="Message" />
            </Form.Item>
            <Button htmlType="submit" className="contact-btn">
              {isLoading ? <Spin /> : "Send Message"}
            </Button>
          </Form>
        </div>
      </Col>
      <Col xs={24} md={12} lg={12} className="contact-first-right-container">
        <Col className="contact-first-right-sub-container">
          <div className="contact-img-container">
            <Image src={vector23} width={320} preview={false} />
          </div>
          <div className="contact-company-info-container">
            <Text className="contact-info-text">Contact information: </Text>
            <Row style={{ maxWidth: "350px" }}>
              <Col span={2}>
                <MailOutlined />
              </Col>
              <Col span={22}>
                <Text className="contact-info-sub-text">
                  info@Lilypad.co.in
                </Text>
              </Col>
            </Row>
            <Row style={{ maxWidth: "350px" }}>
              <Col span={2}>
                <GlobalOutlined />
              </Col>
              <Col span={22}>
                <Text className="contact-info-sub-text">
                  {" "}
                  www.Lilypad.co.in
                </Text>
              </Col>
            </Row>
            <Row style={{ maxWidth: "350px" }}>
              <Col span={2}>
                <EnvironmentOutlined />
              </Col>
              <Col span={22}>
                <Text className="contact-info-sub-text">
                  {" "}
                  HD-396, WeWork Berger Delhi One, Sector 16-B, Noida, UP 201301
                </Text>
              </Col>
            </Row>
          </div>
        </Col>
      </Col>
    </Row>
  );
};

export default FirstSection;
