import React, { useEffect, useRef, useState } from "react";
import vector5 from "../../assets/images/Vector5.svg";
import {
  Row,
  Col,
  Typography,
  Rate,
  Button,
  Carousel,
  Select,
  message,
  Spin,
  Input,
  Flex,
  Tooltip,
  Space,
} from "antd";
import "./productdetailStyle/firstSection.css";
import rangeIcon from "../../assets/range.webp";
import chargingIcon from "../../assets/charging.webp";
import speedIcon from "../../assets/speed.webp";
import batteryIcon from "../../assets/battery.webp";
import {
  LeftOutlined,
  RightOutlined,
  EditOutlined,
  CheckSquareOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { updateCartItem } from "../../Redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart, getUserCartList, userLogin } from "../../api/user";
import { getDealersByPincode } from "../../api/product";
import { getUserPincode } from "../../utils/googleMaps";
import { setUserPincode } from "../../Redux/actionTypes/authAction";
import { Link, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { requestQuote } from "../../api/order";
import { initOTPless } from "../../utils/initOtpless";
const { Text } = Typography;
const contentStyle = {
  margin: 0,
  height: "100%",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
};

const FirstSection = ({
  productData,
  isUnavailable,
  isProductCompare,
  setIsProductCompare,
}) => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => (state ? state.auth : []));
  const [isLocationSelector, setIsLocationSelector] = useState(false);
  const [active, setActive] = useState(false);
  const [quoteApiCalling, setQuoteApiCalling] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userPindCode, setUserPindCode] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [availableDealers, setAvailableDealers] = useState(null);
  const [availableInCity, setAvailableInCity] = useState(false);
  const [availableInDistOrState, setAvailableInDistOrState] = useState(false);
  const [isDemoAvailable, setIsDemoAvailable] = useState(false);
  const [isProductUnAvialable, setIsProductUnAvialable] = useState(false);
  const [isQuoteDisplay, setIsQuoteDisplay] = useState(false);
  const [userData, setUserData] = useState();
  const navigate = useNavigate();

  const callback = async (otplessUser) => {
    const tokenObj = {
      token: otplessUser.token,
      email: otplessUser.email?.email,
      waNumber: otplessUser.waNumber,
      waName: otplessUser.email?.name !== null ? otplessUser.email?.name : "",
    };
    const userData = await userLogin(tokenObj);
    const { auth_token, ...userInfo } = userData.data.data;
    // Save token and user info in localStorage
    localStorage.setItem("auth_token", auth_token);
    localStorage.setItem("user_details", JSON.stringify(userInfo));
    localStorage.setItem("otpless_user_token", JSON.stringify(tokenObj));
    setUserData(otplessUser);
    closeModal();
    navigate(0);
  };

  const openModal = () => {
    if (userData) {
      return;
    }
    initOTPless(callback);
    const modalContainer = document.getElementById("modalContainer");
    if (modalContainer) {
      modalContainer.style.display = "flex";
      modalContainer.style.width = "100%";
    }
    // modalContainer !== null ? (modalContainer.style.display = "flex") : "";
  };
  const closeModal = () => {
    const modalContainer = document.getElementById("modalContainer");
    // if (e.target === modalContainer) {
    // 	modalContainer ? (modalContainer.style.display = 'none') : ''
    // }
    if (modalContainer) {
      modalContainer.style.display = "none";
    }
    const script = document.getElementById("otplessIdScript");
    if (script) {
      script.remove();
    }
    // script ? script.remove() : "";
  };
  const getQuotation = () => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    if (!otpless_user_token) {
      openModal();
    } else {
      setQuoteApiCalling(true);
      const user_data = JSON.parse(localStorage.getItem("user_details"));
      const user_contact_data = JSON.parse(
        localStorage.getItem("otpless_user_token")
      );

      const quoteData = {
        productId: id,
        productName: name,
        userName: user_data.full_name,
        userLocation: selectedLocation,
        status: "test",
        vendorId: vendorId,
        userEmail: user_contact_data.email,
      };
      try {
        requestQuote(quoteData).then((response) => {
          setQuoteApiCalling(false);
          message.success("We are sending you a qutoation");
        });
      } catch (error) {
        setQuoteApiCalling(false);
        message.error("An error occurred!");
      }
    }
  };

  const handleAddToCart = async (itemDetails) => {
    setIsLoading(true);
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    if (otpless_user_token) {
      setIsLoading(false);
      openModal();
    } else {
      const productDetailsAndCount = {
        product_id: itemDetails.id,
        count: 1,
      };
      try {
        addProductToCart(productDetailsAndCount).then((response) => {
          getUserCartList().then((response) => {
            dispatch(updateCartItem(response));
            setIsLoading(false);
          });
        });
      } catch (error) {
        setIsLoading(false);
        message.error("An error occurred!");
      }
    }
    // dispatch(updateCartItem(itemDetails)); // Dispatch action to add item to cart
  };
  // Destructuring values from productData
  const {
    size,
    updateAt,
    countryOfOrigin,
    emailId,
    productFeature,
    name,
    discount,
    ratings,
    sellingPrice,
    productCode,
    careInstruction,
    id,
    color,
    quantity,
    department,
    includedComponents,
    productDimension,
    createdAt,
    productDescription,
    itemWeight,
    isPublished,
    packedBy,
    productPrice,
    range_Overview,
    topSpeed_Overview,
    chargingTime,
    batteryCapacity,
    material,
    productImages,
    vendorId,
  } = productData;

  let imageUrls = productImages?.split(",");

  const onChange = (currentSlide) => {
    //
  };

  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  };

  const handleSearch = async (value) => {
    setIsLoading(true);
    setUserPindCode(value);
    setAvailableInCity(false);
    setAvailableInDistOrState(false);
    if (value.length < 6 || value.length > 6) {
      message.error("Please enter a valid 6-digit Pincode");
    } else {
      setIsLocationSelector(false);

      const body = {
        pincode: value,
        productId: id,
      };

      try {
        const { data } = await getDealersByPincode(body);
        if (data) {
          if (data?.availableDealers) {
            setAvailableInCity(true);
            setIsDemoAvailable(false);
            setIsProductUnAvialable(false);
          } else if (data?.otherDealers) {
            setIsQuoteDisplay(true);
            setAvailableInDistOrState(true);
            setIsProductUnAvialable(true);
            setAvailableDealers(data?.otherDealers.dealers);
            setIsDemoAvailable(true);
          }
          setIsLoading(false);
        }
      } catch (error) {
        message.error("Invalid pincode");
        setIsLoading(false);
      }
    }
  };
  const carouselRef = React.createRef();

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const getPincode = async () => {
    if (
      authState.userPincode === undefined &&
      authState.userCoords !== undefined
    ) {
      const pincode = await getUserPincode(authState.userCoords);
      dispatch(setUserPincode(pincode));
      setSelectedLocation(pincode?.pincode);
    } else {
      setSelectedLocation(authState?.userPincode?.pincode);
    }
  };

  useEffect(() => {
    getPincode();
  }, []);

  useEffect(() => {
    if (isUnavailable) {
      setIsProductUnAvialable(true);
      setIsDemoAvailable(true);
    }
  }, [isUnavailable]);

  return (
    <>
      <Row gutter={16} type="flex" justify="center" align="middle">
        <Col xs={24} sm={24} md={12} lg={10}>
          <Row gutter={16} justify="center">
            <Col
              xs={4}
              sm={4}
              md={4}
              lg={4}
              className="left-arrow-alignment"
              onClick={handlePrev}
            >
              <LeftOutlined style={{ fontSize: "24px", cursor: "pointer" }} />
            </Col>
            <Col xs={16} sm={16} md={16} lg={16}>
              <div className="left-div-border">
                <Carousel ref={carouselRef} afterChange={onChange} dots={false}>
                  {imageUrls?.length > 0 &&
                    imageUrls.map((url, index) => (
                      <div className="image-alignment" key={index}>
                        <img
                          className="product-img"
                          src={url || vector5}
                          alt={"product-img"}
                          style={{
                            ...contentStyle,
                          }}
                        />
                      </div>
                    ))}
                </Carousel>
              </div>
            </Col>
            <Col
              xs={4}
              sm={4}
              md={4}
              lg={4}
              className="right-arrow-alignment"
              onClick={handleNext}
            >
              <RightOutlined style={{ fontSize: "24px", cursor: "pointer" }} />
            </Col>
          </Row>
        </Col>
        <Col xs={22} sm={22} md={12} lg={14}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Flex gap="large" wrap="wrap">
                <div className="heading-style">{name}</div>
                {availableInDistOrState && (
                  <>
                    <InfoCircleOutlined
                      className="product-info-tooltip"
                      onMouseOver={() => {
                        setActive(true);
                      }}
                      onMouseOut={() => {
                        setActive(false);
                      }}
                    />
                    {active && (
                      <div className="product-info-text">
                        <span>Product available in :</span>
                        {availableDealers?.map((e, index) => (
                          <p style={{ color: "#fff" }} key={index}>
                            {e.city}
                          </p>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </Flex>
            </Col>
            {isProductUnAvialable && (
              <Row gutter={20}>
                <Col span={24}>
                  <Flex gap="large" wrap="wrap">
                    <Text type="danger" style={{ paddingLeft: "10px" }}>
                      Product is not available for your city
                    </Text>
                    {isQuoteDisplay &&
                      (quoteApiCalling ? (
                        <Spin />
                      ) : (
                        <Text
                          onClick={getQuotation}
                          type="primary"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Get custom quote
                        </Text>
                      ))}
                  </Flex>
                </Col>
              </Row>
            )}
            {availableInCity && (
              <Row gutter={20}>
                <Col span={24}>
                  <Text type="success" style={{ paddingLeft: "10px" }}>
                    Product is available in your city
                  </Text>
                </Col>
              </Row>
            )}
            {/* {availableInDistOrState && (
              <Row gutter={20}>
                <Col span={24}>
                  <Flex gap="large" wrap="wrap">
                    <Text type="danger" style={{ paddingLeft: "10px" }}>
                      Product is not available in your city
                    </Text>
                    <Text onClick={getQuotation} type="primary" style={{ textDecoration: 'underline', cursor: 'pointer' }}>Get custom quote</Text>
                  </Flex>
                </Col>
              </Row>
            )} */}
            <Row className="location-selector">
              <Col style={{ margin: "0 0.3rem" }}>
                <p>Your Location:</p>{" "}
              </Col>
              {isLocationSelector ? (
                <Col span={6}>
                  <Space.Compact>
                    <Search
                      type="number"
                      ref={inputRef}
                      onKeyDown={handleKeyDown}
                      placeholder="Enter pincode"
                      style={{ color: "#000" }}
                      className="pincode-input"
                      onSearch={handleSearch}
                      defaultValue={selectedLocation}
                      maxLength={6}
                      minLength={6}
                      onChange={(e) => {
                        setSelectedLocation(e.target.value);
                      }}
                    />
                  </Space.Compact>
                  {/* <Select
                 style={{ width: "100%", margin: "0 0" }}
                 onChange={(value) => {
                   setSelectedLocation(value);
                   setIsLocationSelector(false);
                 }}
                 defaultValue={selectedLocation}
                 options={[
                   { value: "Jaipur", label: "Jaipur" },
                   { value: "Karnataka", label: "Karnataka" },
                   { value: "Gudgaon", label: "Gudgaon" },
                   { value: "Delhi", label: "Delhi" },
                 ]}
               /> */}
                </Col>
              ) : (
                <Col>
                  <p
                    style={{ margin: "0 0.3rem", textDecoration: "underline" }}
                  >
                    {" "}
                    {selectedLocation}
                  </p>
                </Col>
              )}{" "}
              <Col
                onClick={() => {
                  setIsLocationSelector(!isLocationSelector);
                }}
                style={{ cursor: "pointer", margin: "0 0.3rem" }}
              >
                <EditOutlined
                  style={{ fontSize: "20px", margin: "0 0.3rem" }}
                />
              </Col>
            </Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div>
                <Rate
                  value={ratings}
                  style={{
                    fontSize: 18,
                    color: "gold",
                    marginRight: "16px",
                    paddingTop: "10px",
                  }}
                />
                <span style={{ color: "gray" }}>{`(${0} Reviews)`}</span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div>
                <div>
                  <Text
                    className="third-card-price"
                    style={{ marginRight: "10px" }}
                  >
                    M.R.P.:
                  </Text>
                  <Text className="cost" delete>
                    ₹{productPrice}
                  </Text>
                  {/* <Text className="third-card-price"> - {discount}%</Text> */}
                  <Text
                    className="cost"
                    style={{ color: "#00FFFF", margin: "20px" }}
                  >
                    ₹{sellingPrice}
                  </Text>
                </div>
                <div></div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={20}>
              <Row
                justify="space-between"
                type="flex"
                style={{ marginTop: "30px" }}
              >
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={4}
                  xl={4}
                  className="feature-icon-wrapper"
                >
                  <Col className="feature-icon">
                    <div>
                      <img
                        className="feature-icon-img"
                        src={rangeIcon}
                        alt=""
                      />
                    </div>
                    <div className="feature-icon-text">{range_Overview}</div>
                  </Col>
                  <p className="feature-name">Range</p>
                </Col>
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={4}
                  xl={4}
                  className="feature-icon-wrapper"
                >
                  <Col className="feature-icon">
                    <div>
                      <img
                        className="feature-icon-img"
                        src={speedIcon}
                        alt=""
                      />{" "}
                    </div>
                    <div className="feature-icon-text">{topSpeed_Overview}</div>
                  </Col>
                  <p className="feature-name">Top Speed</p>
                </Col>
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={4}
                  xl={4}
                  className="feature-icon-wrapper"
                >
                  <Col className="feature-icon">
                    <div>
                      <img
                        className="feature-icon-img"
                        src={chargingIcon}
                        alt=""
                      />{" "}
                    </div>
                    <div className="feature-icon-text">{chargingTime}</div>
                  </Col>
                  <p className="feature-name">Charging Time</p>
                </Col>
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={4}
                  xl={4}
                  className="feature-icon-wrapper"
                >
                  <Col className="feature-icon">
                    <div>
                      <img
                        className="feature-icon-img"
                        src={batteryIcon}
                        alt=""
                      />
                    </div>
                    <div className="feature-icon-text">{batteryCapacity}</div>
                  </Col>
                  <p className="feature-name">Battery</p>
                </Col>
              </Row>
            </Col>
            <Col xs={23} sm={23} md={23} lg={23}>
              <div className="btn-zone2">
                <Button
                  disabled={isProductUnAvialable}
                  className="custom-button2"
                  style={{ marginRight: "15px" }}
                  onClick={() => handleAddToCart(productData)}
                >
                  {isLoading ? (
                    <Spin className="ant-spin-dot-item-custom" />
                  ) : (
                    "Add to cart"
                  )}
                </Button>
                <Link
                  to={{
                    pathname: "/book-a-demo",
                    search: `?productId=${id}&vendorId=${vendorId}&pincode=${userPindCode || authState.userPincode?.pincode
                      }`,
                  }}
                >
                  <Button
                    disabled={isDemoAvailable}
                    className={"custom-button"}
                  >
                    {isLoading ? <Spin /> : "Book Test Ride"}
                  </Button>
                </Link>
                <Button
                  className="custom-button"
                  style={{ marginLeft: "15px" }}
                  onClick={() => setIsProductCompare(!isProductCompare)}
                >
                  Compare <CheckSquareOutlined style={{ fontSize: "16px" }} />
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FirstSection;
