import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Image, Typography, Button, Card } from "antd"
import "./homeStyle/firstSection.css"

const { Text } = Typography

const FirstSection = () => {
    return (
        <Row className='first-main-container'>

            <Col xs={24} md={14} lg={12} className='first-left-container'>
                <Col className='first-left-sub-container'>
                    <Text className='first-title'>India’s Newest EV Destination</Text>
                    <Text className='first-subtitle'>Changing the way you move with our finest collection of electric scooters, motorcycles, hoverboards & premium range of accessories.</Text>
                    <Link to={'/products'}>
                        <Button className='first-explore-btn-gred'><b>Explore Our Range</b></Button>
                    </Link>
                </Col>
            </Col>

            <Col xs={24} md={10} lg={12} className='first-right-container'>
                <Image src='./../../images/visionhero.webp' className="vector1" preview={false} />
            </Col>
        </Row>
    )
}

export default FirstSection