import { Col, Row, Typography, Image } from 'antd'
import "./poweringUpStyle/firstSection.css"


const FirstSection = () => {

  const { Text } = Typography

  return (
    <div>
      <Row className='powering-fourth-section-container'>
        <Col span={24} className='powering-top-container'>
          <Col className='powering-text' >
            <Text className='powering-fourth-title'>Join us in Powering India's EV Transition</Text>
            <Text className='about-fourth-description'>
              We're commited to making sustainable & conscious travel to India
              <br />
              We have big plans to bring this rEVolution, and for that we need your support. Join us to see how our journey will look alike.
            </Text>
          </Col>

          <Col className='image-section' >
            <Image src="/images/powering-up-battery.png" preview={false} />
          </Col>
        </Col>
      </Row>
    </div>
  )
}

export default FirstSection