import { Button, Col, Row, Typography } from 'antd'
import rightIcon from '../assets/images/rightIcon.svg'
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

const OrderSuccess = () => {
    const navigate = useNavigate();

    return (
        <Row align={"middle"}>
            <Col>
                <div className='order-success-container'>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='outer-circle'>
                            <div className='inner-circle'>
                                <img src={rightIcon} height={40} />
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: '20px 0 10px 0' }}>
                        <Text className='order-success-header' style={{ color: "#fff" }}>Purchase Success!</Text>
                    </div>
                    <div className='order-success-help-container'>
                        <Text style={{ color: "#fff" }} className='order-success-help-text'>Thank you for your order at <b>LILYPAD</b>. Your order will be processed as soon as possible. You will be receiving an email shortly with invoice from your order.</Text>
                    </div>
                    <div className='order-success-btn-container'>
                        <Button
                            className='outline-btn'
                            onClick={() => navigate('/products')}
                        >
                            Continue Shopping
                        </Button>
                        <Button
                            className='gradient-btn'
                            onClick={() => navigate('/user/orders')}
                        >
                            Track your order
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default OrderSuccess;