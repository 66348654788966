import { Button, Col, Row, Spin, Typography, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatToRupees } from "../../utils/formatToRupees";
const { Text } = Typography;

const ResponsiveOrderSummary = ({ onOrderPlace, paymentMethod, isLoading, windowWidth, address, getOrderTotalAmount }) => {
    const [totalAmount, setTotalAmount] = useState(0);

    const [subTotalAmount, setSubTotalAmount] = useState(0);
    const [shippingCharge, setShippingCharge] = useState(50);
    const [gstCharge, setGstCharge] = useState(0);
    // const cartItems = useSelector((state) => state.cart.cartItems);

    const handleOrderPlace = () => {
        if (!paymentMethod) {
            message.error("Please select payment method first");
        } else onOrderPlace(address, paymentMethod);
    };

    // const getVariantPrice = (cartItem) => {
    //     if (cartItem && cartItem.variants && cartItem.variants.length) {
    //         let variant = cartItem.variant;
    //         let selectedVariant = cartItem.variants.filter(item => item.spec === variant);

    //         if (selectedVariant.length > 0) {
    //             return selectedVariant[0].price;
    //         }
    //     }
    //     return cartItem.sellingPrice;
    // };


    // const getTotalAmount = (cartItems) => {

    //     let subTotal = 0;
    //     let totalAmount = 0;
    //     let charge = 0;
    //     let gst = 0;
    //     if (cartItems.length) {
    //         cartItems.forEach((item) => {
    //             subTotal += (item.booking_type == "booking") ? (item.bookingPrice * item.count) : (((item.variant && item.variant != null) ? getVariantPrice(item) : item.sellingPrice) * item.count);
    //             if (!item.priceIncludesDelivery) {
    //                 charge += ((item.booking_type == "booking") ? 0 : ((item.variant && item.variant != null) ? getVariantPrice(item) : item.sellingPrice) * 5) / 100
    //             }
    //         });
    //     }
    //     totalAmount = subTotal + charge
    //     gst = (totalAmount * 5) / 100
    //     totalAmount += gst
    //     setSubTotalAmount(subTotal.toFixed(2))
    //     setTotalAmount(totalAmount.toFixed(2));
    //     setShippingCharge(charge.toFixed(2));
    //     setGstCharge(gst.toFixed(2))
    //     getOrderTotalAmount(totalAmount.toFixed(2))
    // };
    // useEffect(() => {
    //     getTotalAmount(cartItems);
    // }, [cartItems]);

    return (
        <Col xs={24} sm={24} md={12}>
            <div className="payment-btn-container">
                <Button className="gradient-btn"
                    onClick={() => handleOrderPlace()}
                    disabled={isLoading}
                >
                    {isLoading ? <Spin /> : (paymentMethod ? "Place order" : "Continue To Payment")}
                </Button>
            </div>
        </Col>
    );
};

export default ResponsiveOrderSummary;
